<template>
  <div>
    <!-- Banner Section -->
    <section class="banner d-flex align-items-center justify-content-center cleartx">
            <div class="container-fluid">
                <img src="../assets/img/CaseStudies/Blog.jpg" class="img" alt="csr">
                <div class="container csrbanner">
                    <h2 class="csrbannertitle">Blogs</h2>
                </div>
            </div>
    </section>
   
    <section class="cleartx mt-4">
            <div class="container">
                <div class="row g-5">
                    <div class="col-sm-12 mt-5">
                        <h2 class="blog-title">Rooted in Legacy, Designed for
                            Tomorrow: Arete’s Brand Evolution</h2>
                    </div>
                    <div
                        class="col-sm-12 d-flex flex-column justify-content-center">
                        <p class="blog-desc">Founded in the year 1990, Arete’s
                            journey has been paved by sustained growth and
                            strategic diversification across multitudinous
                            sectors. Grounded on a foundation of unwavering
                            values, Arete’s growth trajectory is a testament to
                            resilience and powerful expansion. The Arete Group
                            today covers a wide range of sectors including, but
                            not limited to, industrial parks, commercial and
                            residential real estate, hospitality, retail and
                            investments. Each of these is a paragon of
                            excellence and innovation.</p>
                        <p class="blog-desc">The identity has two parts: <br>
                            1. Visual: A powerful illustration of a human figure constantly in motion, symbolizing growth and striving towards excellence, this figure doubles as the letter ‘a’, capturing The Arete Group’s core belief that it is values that catalyze growth and direct us towards excellence.<br>
                            2. Brand Line: A line that completes the message “Values create Value”
                        </p>

                        <p class="blog-desc">There are aspects of any brand that
                            are subject to change over time, some cannot. The
                            Arete Group brand is one such aspect that is
                            enduring</p>

                            <p class="blog-desc">Brand Arete encourages the belief that exponential growth is a fruit of values. These values are the propellants of excellence and sustainable advancement.</p>
                            <p class="blog-desc">Our four core values are the bedrock of our philosophy.</p>
                            <p class="blog-desc"><b>Excellence</b>-We set high benchmarks and we do not stop until we achieve the best quality and results.</p>
                            <p class="blog-desc"><b>Empowerment</b>- We promote a culture of respect and responsibility, striving to bring out the best in every team member and expecting them to perform their duties responsibly.</p>
                            <p class="blog-desc"><b>Empathy</b>-We prioritize caring and understanding in order to build diverse workforce and good relations with our stakeholders</p>
                            <p class="blog-desc"><b>Resilience</b>-We build flexibility in the structure of the organization to help adopt changes, foster innovations, and resolve problems artistically and efficiently</p>

                            <p class="blog-desc">Entering this new phase of Arete with a revised identity, we continue to embrace the ideals that have sustained us through the past three decades. This transformation is more than just a new look; it's a reflection of our ongoing journey to innovate, adapt, and serve with excellence. With a strong legacy, we are excited to build a future that resonates with our vision for excellence, growth and leadership in the industry. We invite our partners, clients, employees and community to embrace this transformation with us as we continue to shape the future together.</p>
    

                    </div>
                </div>
            </div>
    </section>
    
  </div>
</template>

<script>

</script>

<style>

</style>