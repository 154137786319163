<template>
  <div>
      <!-- Banner Section -->
     <section class="banner d-flex align-items-center justify-content-center cleartx">
        <div class="container-fluid">
            <img src="../assets/img/contactus/homesection.jpg" class="img" alt="csr">
            <div class="container csrbanner">
                <h2 class="csrbannertitle" data-aos="fade-up">Contact Us</h2>
            </div>
        </div>
    </section>


    <!-- /-----------------------------------Contact us  body-------------------- -->
    <section class="cleartx">
        <div class="container">
            <div class="row">
                <div class="container mt-5 p-5 rounded bg-light shadow-sm">
                    <!-- Buttons -->
                    <div class="d-flex justify-content-start mb-4">
                        <button class="form-enq-btn btn-gradient me-3" :class="{ active: isActive === 'enquiry' }" @click="setActive('enquiry')">Enquiry</button>
                        <button class="form-partner-btn" :class="{ active: isActive === 'partner' }" @click="setActive('partner')">Become a partner</button>
                    </div>
                    <!-- Form -->
                    <form  @submit.prevent="checkSubmit" method="post">
                        <div class="row mt-5 mb-4">
                            <div class="col-md-12">
                                <label for="name" class="form-label">Name</label>
                                <input type="text" v-model="name" class="form-control form-underline bg-transparent" id="name"
                                    placeholder="Bruce Wayne" >
                            </div>
                        </div>
                        <div class="row mb-4">
                            <div class="col-md-5">
                                <label for="phone" class="form-label">Mobile no.</label>
                                <input  class="form-control form-underline bg-transparent text-box single-line" oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"  pattern="[^[6-9]\d{9}$]" maxLength="10" id="mobile" v-model="mobile" placeholder="Enter your Mobile No" type="number">

                            </div>  
                            <div class="col-md-1"></div>
                            <div class="col-md-6">
                                <label for="email" class="form-label">Email</label>
                                <input type="email" v-model="email" class="form-control form-underline bg-transparent" id="email"
                                    placeholder="brucewayne@domain.com" >
                            </div>
                        </div>
                        <div class="row mb-4">
                            <div class="col-md-6">
                              <div class="mb-3 d-flex align-items-center"> <!-- Add spacing and align label/select -->
                                <label for="enquire" class="form-label me-3">I would like to enquire about:</label> <!-- Bootstrap spacing using me-3 -->
                                <select id="enquire" v-model="enquire" name="enquire" class="form-select rounded-pill w-auto" @change="handleEnquireChange" > <!-- Set auto width for select -->
                                  <option value="industrial">Industrial Parks</option>
                                  <option value="retail">Retail</option>
                                  <option value="hospitality">Hospitality</option>
                                  <option value="RealEstate">Real Estate</option>
                                </select>
                              </div>

                              <div class="checkbox-group mt-4" v-if="showPipCheckboxes">
                                <label class="me-3">
                                  <input type="checkbox" id="checkbox1" v-model="selectedIndustrial" checked> PIP Dahej
                                </label>
                              </div>

                              <div class="checkbox-group mt-4" v-if="showHospitalityCheckboxes">
                                <label class="me-3">
                                  <input type="checkbox" v-model="selectedHospitality" id="checkbox2" checked> Fortune Park Galaxy, Vapi
                                </label>
                                <label class="me-3">
                                  <input type="checkbox" v-model="selectedHospitality" id="checkbox3" > Fortune Park Galaxy, Kevadia
                                </label>
                                <label class="me-3">
                                  <input type="checkbox" v-model="selectedHospitality" id="checkbox4" > Sam's Alive Again
                                </label>
                                <label class="me-3">
                                  <input type="checkbox" v-model="selectedHospitality" id="checkbox5" > Woodlands Restaurant
                                </label>
                              </div>
                                                  
                            </div>
                        </div>
 
                        <div class="row mb-4">
                            <div class="col-md-12">
                                <label for="message" class="form-label">Message (optional)</label>
                                <input type="text" v-model="Message" class="form-control form-underline bg-transparent" id="message"
                                    placeholder="Write your message here" >
                            </div>
                        </div>
 
                        <div class="d-flex justify-content-left mt-3">
                            <button type="submit" class="form-enq-btn btn-gradient me-3">Submit</button>
                        </div>

                    </form>
                </div>

            </div>
        </div>
    </section>
 
    <section>
        <div class="container">
            <div class="row p-0">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3740.215764606756!2d72.91765937506233!3d20.37399178111656!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be0ce281606c553%3A0x92a88a448a365fdc!2sFortune%20Park%20Galaxy%2C%20Vapi%20-%20Member%20ITC&#39;s%20hotel%20group!5e0!3m2!1sen!2sin!4v1727855636860!5m2!1sen!2sin"  style="border:0;width: 100%; height: 403px;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
        </div>
    </section>
 
    <section>
        <div class="container mt-4">
            <div class="row contact-info">
              <!-- Address -->
              <div class="col-md-4 mb-4">
                <div class="d-flex">
                  <i class="fas fa-map-marker-alt"></i> <!-- Address Icon -->
                  <div>
                    <h5>Address</h5>
                    <p><strong>Arete Group</strong><br>
                       3rd & 4th Floor, Galaxy High Street GIDC,<br>
                       Vapi 396195, Gujarat, India
                    </p>
                  </div>
                </div>
              </div>
       
              <!-- Email -->
              <div class="col-md-4 mb-4">
                <div class="d-flex">
                  <i class="fas fa-envelope"></i> <!-- Email Icon -->
                  <div>
                    <h5>Email</h5>
                    <p><a href="mailto:asplroc@areteservices.in" class="text-black text-decoration-none">asplroc@areteservices.in</a></p>
                  </div>
                </div>
              </div>
       
              <!-- Phone -->
              <div class="col-md-4 mb-4">
                <div class="d-flex">
                  <i class="fa-solid fa-phone"></i> <!-- Phone Icon -->
                  <div>
                    <h5>Phone</h5>
                    <p><a href="tel:+919712956842" class="text-black text-decoration-none">+91 97129 56842</a></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
       
    </section>
    <!-- /-----------------------------------Contact us -------------------- -->
    
  </div>
</template>

<script>
import { mapActions } from "vuex"

export default {
  name: "ContactUsPage", // or another multi-word name
  data() {
    return {
      name:'',
      email: '',
      mobile:'',
      enquire: 'industrial',
      Message:'', // Default selected value
      isActive: 'enquiry', // Default active button
      showPipCheckboxes: true,
      showHospitalityCheckboxes: false,
      selectedIndustrial: [], // Stores checked values for industrial options
      selectedHospitality: [], // Stores checked values for hospitality options
    };
  },
  computed: {
    ...mapActions(['submitdata']),
      },
  methods: {
    handleEnquireChange() {
      this.showPipCheckboxes = this.enquire === 'industrial';
      this.showHospitalityCheckboxes = this.enquire === 'hospitality';

      if (!this.showPipCheckboxes) this.selectedIndustrial = [];
      if (!this.showHospitalityCheckboxes) this.selectedHospitality = [];

    },
    setActive(button) {
      this.isActive = button; // Set the active button
    },
    async checkSubmit()
    { 

      let payload={
        name:this.name,
        email:this.email,
        mobile:this.mobile,
        enquire:this.enquire,
        Message:this.Message,
        type:this.isActive
      }

      console.log('payload',payload);

         await this.$store.dispatch('submitdata',payload);

               this.$swal({
                    text: 'Thank you for contacting us! Your message has been successfully received.',
                    target: '#custom-target',
                    timer: 2000,
                    toast: true,
                    position: 'top'
                });

                this.clearForm();
                // setTimeout(() => {
                //   //this.$router.push('/contactus');
                // }, 1000);
         
      
    },
    clearForm() {
      this.name="";
        this.email="";
        this.mobile="";
        this.Message="";
      }
  }
};
</script>

<style scoped>
    /* Underline style for form inputs */
    .form-underline {
    border: none;
    border-bottom: 2px solid #ced4da;
    border-radius: 0;
    padding-left: 0;
    box-shadow: none;
}
 
.form-underline:focus {
    border-bottom: 2px solid #007bff;
    outline: none;
    box-shadow: none;
}
 
textarea {
    resize: none;
}
 
/* Style for checked input */
.form-check-input:checked {
    background-color: #007bff;
}
.form-control {
    background: none;
}
 
.form-enq-btn {
    width: 120px; /* Hug width */
    height: 45px; /* Fixed height */
    padding: 0px 5px; /* Padding */
    gap: 10px; /* Gap between text and border */
    border-radius: 51px; /* Rounded top-left corner */
    border: 1px solid black; /* Transparent border */
    background-color: transparent; /* No background */
    color: #333; /* Button text color */
    font-size: 16px;
    text-align: center;
    transition: all 0.3s ease; /* Smooth transition */
}
 
 
 
/* Hover effect for the button */
.form-enq-btn:hover {
    background: linear-gradient(45deg, rgba(120, 205, 208, 1), rgba(191, 212, 47, 1));
    color: white;
    border: 1px solid white;
    cursor: pointer;
}
 
 
.form-partner-btn {
    width: 200px; /* Hug width */
    height: 45px; /* Fixed height */
    padding: 0px 5px; /* Padding */
    gap: 10px; /* Gap between text and border */
    border-radius: 51px; /* Rounded top-left corner */
    border: 1px solid black; /* Transparent border */
    background-color: transparent; /* No background */
    color: #333; /* Button text color */
    font-size: 16px;
    text-align: center;
    transition: all 0.3s ease; /* Smooth transition */
}
 
 
 
/* Hover effect for the button */
.form-partner-btn:hover {
    background: linear-gradient(45deg, rgba(120, 205, 208, 1), rgba(191, 212, 47, 1)); /* Linear gradient on hover */
    color: white; /* Change text color to white on hover */
    border: 1px solid white; /* Border color on hover */
    cursor: pointer; /* Show pointer cursor */
}
 
 
.dropdown {
    position: relative;
    display: inline-block;
  }
 
.dropdown select {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    padding: 10px;
    border-radius: 50px; /* Rounded corners */
    border: 1px solid #ccc;
    background: white;
    font-size: 16px;
    width: 200px;
    text-align: center;
    padding-right: 40px; /* Space for arrow */
  }
 
  .dropdown::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 20px;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid black; /* Custom arrow, small and minimalistic */
    transform: translateY(-50%);
    pointer-events: none; /* Prevent interaction with the arrow */
  }
 
  .checkbox-group {
    margin-top: 10px;
  }
 
  .checkbox-group label {
    display: inline-block;
    margin-right: 20px; /* Adjust this value to control the gap */
  }
 
  .checkbox-group input {
    margin-right: 5px;
  }
 
  .contact-info i {
    font-size: 1.5rem;
    color: #333;
    margin-right: 10px;
  }
  .contact-info h5 {
    font-size: 1.25rem;
    margin-bottom: 0.5rem;
  }
  .form-enq-btn.active,
.form-enq-btn:hover {
    background: linear-gradient(45deg, rgba(120, 205, 208, 1), rgba(191, 212, 47, 1));
    color: white;
    border: 1px solid white;
}
 
.form-partner-btn.active,
.form-partner-btn:hover {
    background: linear-gradient(45deg, rgba(120, 205, 208, 1), rgba(191, 212, 47, 1)); /* Linear gradient on hover */
    color: white;
    border: 1px solid white;
}

</style>