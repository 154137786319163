<template>
    <div>
      <section class="cleartx">
          <div class="container">
            <div class="row">
              <h2 class="privacy">Arete Privacy Policy and Disclaimer</h2>
  
  <ol>
    <li><strong>Privacy Policy</strong></li>
  </ol>

  <p>Effective Date: {{ effectiveDate }}</p>

  <p><strong>Introduction</strong></p>
  <p>
    Arete Services Private Limited ("<strong>we</strong>", "<strong>our</strong>",
    or "<strong>us</strong>") is committed to protecting the privacy and
    security of our website’s visitors ("<strong>you</strong>", "<strong>your</strong>").
    This Privacy Policy outlines what information and data we collect, how we
    use it, and how we protect the information you provide while using our
    website.
  </p>

  <ol>
    <li><strong>Information We Collect</strong></li>
  </ol>

  <p>We collect two types of information:</p>

  <ul>
    <li>
      <strong>Cookies and Tracking Technologies:</strong> We use cookies to
      enhance your experience on our website. Cookies are small files stored
      on your device that help us understand how visitors interact with the
      website, improve site performance, and personalize content. You will be
      asked to provide consent for cookie usage when you first visit our site.
      You can manage cookie settings through your browser.
    </li>
    <li>
      <strong>Form Submissions:</strong> You may voluntarily provide digital
      personal data by filling out forms on our website. The information
      collected includes:
      <ul>
        <li>Name</li>
        <li>Email address</li>
        <li>Mobile number</li>
        <li>Segment of interest (Industrial Parks, Retail, Hospitality, Investments)</li>
        <li>Any additional information you choose to include in the message field</li>
      </ul>
    </li>
  </ul>

  <ol start="2">
    <li><strong>How We Use Your Information</strong></li>
  </ol>

  <p>The information we collect is used for the following purposes:</p>

  <ul>
    <li>
      <strong>To Provide Services:</strong> To respond to inquiries, send
      requested information, and provide updates regarding our services in the
      relevant segment of interest.
    </li>
    <li>
      <strong>To Improve User Experience:</strong> To enhance our website’s
      functionality, performance, and usability by analysing cookie data.
    </li>
    <li>
      <strong>Communication:</strong> To communicate with you about your
      inquiries and provide you with information that may be of interest,
      related to the segments you have selected and for any other marketing
      activities.
    </li>
  </ul>

  <ol start="3">
    <li><strong>Sharing Your Information</strong></li>
  </ol>

  <p>
    We do not sell or rent your personal information to third parties.
    However, we may share your information with:
  </p>

  <ul>
    <li>
      <strong>Service Providers:</strong> Third-party companies who assist us
      in operating our website, conducting business, or servicing you,
      provided they agree to keep this information confidential.
    </li>
    <li>
      <strong>Legal Compliance:</strong> If required by law, we may disclose
      your information in response to legal requests from government
      authorities.
    </li>
  </ul>

  <ol start="4">
    <li><strong>Your Consent</strong></li>
  </ol>

  <p>
    By using our website and submitting forms, you consent to the collection
    and use of your personal information as described in this Privacy Policy.
    You will be given the option to provide or withdraw consent for optional
    cookies when you first access our site. If you wish to modify your cookie
    settings later, you may do so through your browser. However, we reserve
    the right to refuse access to our website if you choose to not allow
    mandatory cookies.
  </p>

  <ol start="5">
    <li><strong>Data Security</strong></li>
  </ol>

  <p>
    We take reasonable measures to protect the digital personal data you
    provide. While no method of transmission over the Internet or method of
    electronic storage is 100% secure, we use industry-standard practices to
    protect your personal data from unauthorized access, disclosure, or
    misuse.
  </p>

  <ol start="6">
    <li><strong>Your Rights</strong></li>
  </ol>

  <p>You have the right to:</p>

  <ul>
    <li>Access the personal information we hold about you.</li>
    <li>Request the correction or deletion of your personal information.</li>
    <li>Withdraw consent for cookies or other data collection practices at any time.</li>
  </ul>

  <p>If you would like to exercise any of these rights, please contact us at {{ contactEmail }}.</p>

  <ol start="7">
    <li><strong>Changes to This Privacy Policy</strong></li>
  </ol>

  <p>
    We may update this Privacy Policy from time to time without providing
    notice. Any changes will be posted on this page with an updated "Effective
    Date." We encourage you to review this Privacy Policy periodically to stay
    informed about how we protect your information.
  </p>

  <ol start="8">
    <li><strong>Contact Us</strong></li>
  </ol>

  <p>
    If you have any questions or concerns about this Privacy Policy or our
    data practices, please contact us at:
  </p>

  <p>
    Arete Services Private Limited<br />
    {{ contactInfo }}<br />
    {{ contactEmail }}<br />
    {{ contactPhone }}
  </p>

  <ol>
    <li><strong>Disclaimer</strong></li>
    <li><strong>General Information</strong></li>
  </ol>

  <p>
    The content of this website is provided for general information purposes
    only and does not constitute professional advice. Arete Services Private
    Limited ("<strong>Arete</strong>") makes no representations or warranties
    of any kind, express or implied, about the completeness, accuracy,
    reliability, suitability, or availability of the website or the
    information, products, services, or related graphics contained on the
    website for any purpose. Any reliance you place on such information is
    strictly at your own risk.
  </p>

  <ol start="2">
    <li><strong>Limitation of Liability</strong></li>
  </ol>

  <p>
    In no event will Arete or its affiliates, employees, or agents be liable
    for any loss or damage, including without limitation, indirect or
    consequential loss or damage, or any loss or damage whatsoever arising
    from loss of data or profits arising out of, or in connection with, the
    use of this website.
  </p>

  <p>
    Through this website, you may be able to link to other websites that are
    not under the control of Arete. We have no control over the nature,
    content, and availability of those sites, and the inclusion of any links
    does not necessarily imply a recommendation or endorsement of the views
    expressed within them.
  </p>

  <ol start="3">
    <li><strong>Copyright and Intellectual Property</strong></li>
  </ol>

  <p>
    All media, literature, videos, images, and any other data (including but
    not limited to designs, layouts, and graphical content) available on this
    website are the copyrighted and proprietary intellectual property of
    Arete, unless otherwise stated. All rights are reserved.
  </p>

  <p>
    No visitor, whether a natural person or a corporate entity, may use,
    reproduce, distribute, modify, display, or perform any part of the content
    on this website for any purpose without the express written consent of the
    Company. Unauthorized use may constitute a violation of copyright,
    trademark, and other applicable laws.
  </p>

  <ol start="4">
    <li><strong>Consent for Use</strong></li>
  </ol>

  <p>
    Visitors are prohibited from using the information, media, or other
    proprietary materials from this website for commercial purposes, personal
    gains, or any form of dissemination, without prior consent from Arete. If
    you wish to obtain permission to use any part of this website’s content,
    please contact us at {{ contactInfo }}.
  </p>

  <ol start="5">
    <li><strong>Changes to the Disclaimer</strong></li>
  </ol>

  <p>
    Arete reserves the right to amend or update this disclaimer at any time
    without notice. By using this website, you agree to the terms and
    conditions outlined in this disclaimer and to any future revisions that
    may occur.
  </p>

  <ol start="6">
    <li><strong>Contact Us</strong></li>
  </ol>

  <p>
    If you have any questions regarding this disclaimer, please contact us at:
  </p>

  <p>
    {{ contactInfo }}<br />
    {{ contactEmail }}<br />
    {{ contactPhone }}
  </p>
            </div>
          </div>
      </section>
     
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        effectiveDate: "January 1, 2024",
        contactInfo: "Arete Services Private Limited",
        contactEmail: "info@arete.com",
        contactPhone: "+123-456-7890"
      };
    }
  };
  </script>
  
  <style scoped>
  .privacy {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  ol {
    margin: 20px 0;
  }
  
  ul {
    margin-left: 20px;
  }
  </style>
  