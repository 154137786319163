<template>
    <div>
        <!-- Banner Section -->
        <section class="banner d-flex align-items-center justify-content-center cleartx">
            <div class="container-fluid">
                <img src="../assets/img/CaseStudies/NewsroomBanner.png" class="img" alt="csr">
                <div class="container csrbanner">
                    <h2 class="csrbannertitle" data-aos="fade-up">Newsroom</h2>
                </div>
            </div>
        </section>


        <section class="cleartx mt-5 mt-md-0">
            <div class="container">
                <div class="row mb-4">
                    <div class="col-md-6">
                        <h2 class="mediaTitle">Arete in The News</h2>
                    </div>
                    <div class="col-md-6 d-flex justify-content-end align-items-center">
                    </div>
                </div>
                <div class="row">
                    <!-- Card 1 -->
                    <div class="col-md-4">
                        <div class="card border-0">
                            <img src="../assets/img/Media/newsroom1.png" class="card-img-top" alt="Media topic">
                            <div class="card-body border-0">
                                <button type="button"
                                    class="btn btn-outline-dark rounded-pill custom-badge-load px-4">News</button>
                                <button type="button" class="btn btn-outline-dark rounded-pill custom-badge-load">25th October, 2024</button>
                                <h5 class="card-title mt-3">BW Business World</h5>
                                <p>PIP’s Development Offers Prosperous Future For
                                    Chemical And Petrochemic...</p>
                            </div>
                        </div>
                    </div>
                    <!-- Card 2 -->
                    <div class="col-md-4">
                        <div class="card border-0">
                            <img src="../assets/img/Media/newsroom2.png" class="card-img-top" alt="Media topic">
                            <div class="card-body border-0">
                                <button type="button"
                                    class="btn btn-outline-dark rounded-pill custom-badge-load px-4">News</button>
                                <button type="button" class="btn btn-outline-dark rounded-pill custom-badge-load">25th October, 2024</button>
                                <h5 class="card-title mt-3">Free Press Journal</h5>
                                <p>“PIP Aspires To Be Asia’s Ultimate Destination To Set
                                    Up Industry” – Siraj...</p>
                            </div>
                        </div>
                    </div>
                    <!-- Card 3 -->
                    <div class="col-md-4">
                        <div class="card border-0">
                            <img src="../assets/img/Media/newsroom3.png" class="card-img-top" alt="Media topic">
                            <div class="card-body border-0">
                                <button type="button"
                                    class="btn btn-outline-dark rounded-pill custom-badge-load px-4">News</button>
                                <button type="button" class="btn btn-outline-dark rounded-pill custom-badge-load">25th October, 2024</button>
                                <h5 class="card-title mt-3">Construction Week
                                    Online</h5>
                                <p>The surprising role of industrial parks in India’s
                                    economic expansion...</p>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-4">
                        <div class="card border-0">
                            <img src="../assets/img/Media/newsroom1.png" class="card-img-top" alt="Media topic">
                            <div class="card-body border-0">
                                <button type="button"
                                    class="btn btn-outline-dark rounded-pill custom-badge-load px-4">News</button>
                                <button type="button" class="btn btn-outline-dark rounded-pill custom-badge-load">25th October, 2024</button>
                                <h5 class="card-title mt-3">BW Business World</h5>
                                <p>PIP’s Development Offers Prosperous Future For
                                    Chemical And Petrochemic...</p>
                            </div>
                        </div>
                    </div>
                    <!-- Card 2 -->
                    <div class="col-md-4">
                        <div class="card border-0">
                            <img src="../assets/img/Media/newsroom2.png" class="card-img-top" alt="Media topic">
                            <div class="card-body border-0">
                                <button type="button"
                                    class="btn btn-outline-dark rounded-pill custom-badge-load px-4">News</button>
                                <button type="button" class="btn btn-outline-dark rounded-pill custom-badge-load">25th October, 2024</button>
                                <h5 class="card-title mt-3">Free Press Journal</h5>
                                <p>“PIP Aspires To Be Asia’s Ultimate Destination To Set
                                    Up Industry” – Siraj...</p>
                            </div>
                        </div>
                    </div>
                    <!-- Card 3 -->
                    <div class="col-md-4">
                        <div class="card border-0">
                            <img src="../assets/img/Media/newsroom3.png" class="card-img-top" alt="Media topic">
                            <div class="card-body border-0">
                                <button type="button"
                                    class="btn btn-outline-dark rounded-pill custom-badge-load px-4">News</button>
                                <button type="button" class="btn btn-outline-dark rounded-pill custom-badge-load">25th October, 2024</button>
                                <h5 class="card-title mt-3">Construction Week
                                    Online</h5>
                                <p>The surprising role of industrial parks in India’s
                                    economic expansion...</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    </div>
</template>

<script>
export default {
  name: 'AllRoomComponent', // Changed from 'Newsroom'
  // Your existing component logic
};
</script>

<style scroped>
.responsive {
    max-width: 100%;
    height: auto;
}
</style>
