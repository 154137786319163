import { createWebHashHistory, createRouter } from "vue-router";
// import TmsLogin from "../views/TmsLogin.vue";
import Home from "../views/HomeView.vue";
import About from "../views/AboutView.vue";
import Values from "../views/ValuesView.vue";
import Csr from "../views/CsrView.vue";
import IndustrialParksView from "../views/IndustrialParksView.vue";
import HospitalityView from "../views/HospitalityView.vue";
import RetailView from "../views/RetailView.vue";
import Investments from "../views/InvestmentView.vue";
import RealEstateView from "../views/RealEstateView.vue";
import LifeAtArete from "../views/LifeAtArete.vue";
import Contactus from "../views/Contactus.vue";
import Media from "../views/MediaView.vue";
import SocialMediaDetail from "../views/SocialMediaDetail.vue";
import CaseStudies from "../views/CaseStudies.vue";
import CaseStudiesDetails from "../views/CaseStudiesDetails.vue";
import Mainblogs from "../views/Mainblogs.vue";
import MainblogsDetails from "../views/MainblogsDetails.vue";
import Newsroom from "../views/Newsroom.vue";
import PrivacyPolicy  from "../views/PrivacyPolicy.vue";


const routes = [
    {
        path: "/PrivacyPolicy",
        name: "PrivacyPolicy",
        component: PrivacyPolicy,
        meta: {
            title:'Privacy Policy',
            reload: true,
          },
      },
      {
      path: "/",
      name: "Home",
      component: Home,
      meta: {
        title:'Home',
          reload: true,
        },
    },
    {
      path: "/about",
      name: "About",
      component: About,
      meta: {
        title:'About',
          reload: true,
        },
    },
    {
        path: "/Values",
        name: "Values",
        component: Values,
        meta: {
            title:'Values',
            reload: true,
          },
    },
    {
        path: "/csr",
        name: "csr",
        component: Csr,
        meta: {
            title:'Csr',
            reload: true,
          },
    },
    {
        path: "/Industrial-parks",
        name: "IndustrialParksView",
        component: IndustrialParksView,
        meta: {
            title:'industrial-parks',
            reload: true,
          },
    },
    {
        path: "/Hospitality",
        name: "HospitalityView",
        component: HospitalityView,
        meta: {
            title:'Hospitality',
            reload: true,
          },
    },
    {
        path: "/retail",
        name: "RetailView",
        component: RetailView,
        meta: {
            title:'Retail',
            reload: true,
          },
    },
    {
        path: "/Investments",
        name: "Investments",
        component: Investments,
        meta: {
            title:'Investments',
            reload: true,
          },
    },
    // RealEstateView
    {
        path: "/RealEstate",
        name: "RealEstateView",
        component: RealEstateView,
        meta: {
            title:'RealEstate',
            reload: true,
          },
    },
    {
        path: "/Life-at-Arete",
        name: "LifeAtArete",
        component: LifeAtArete,
        meta: {
            title:'Life-at-Arete',
            reload: true,
          },
    },
    {
        path: "/Contactus",
        name: "Contactus",
        component: Contactus,
        meta: {
            title:'Contactus',
            reload: true,
          },
    },
    {
        path: "/media",
        name: "Media",
        component: Media,
        meta: {
            title:'Media',
            reload: true,
          },
    },
    {
        path: "/social-media-detail",
        name: "SocialMediaDetail",
        component: SocialMediaDetail,
        meta: {
            title:'Social-media-detail',
            reload: true,
          },
    },
    {
        path: "/case-studies",
        name: "CaseStudies",
        component: CaseStudies,
        meta: {
            title:'Case-studies',
            reload: true,
          },
    },
    {
        path: "/case-studies-details",
        name: "CaseStudiesDetails",
        component: CaseStudiesDetails,
        meta: {
            title:'Case-studies-details',
            reload: true,
          },
          props: route => ( { query: route.query.q } )
    },
    {
        path: "/Mainblogs",
        name: "Mainblogs",
        component: Mainblogs,
        meta: {
            title:'Blogs',
            reload: true,
          },
    },
    {
        path: "/MainblogsDetails",
        name: "MainblogsDetails",
        component: MainblogsDetails,
        meta: {
            title:'Blogs',
            reload: true,
          },
    },
    {
        path: "/Newsroom",
        name: "Newsroom",
        component: Newsroom,
        meta: {
            title:'Newsroom',
            reload: true,
          },
    },




];

const router = createRouter({
    mode: 'history',
    linkExactActiveClass: 'active',
    history: createWebHashHistory(),
    routes,
    scrollBehavior(to, from, savedPosition) {
        // If saved position is available (for back/forward navigation), use it
        if (savedPosition) {
            return savedPosition;
        } else {
            // Otherwise, scroll to the top of the page
            return { top: 0 };
        }
    }
});

router.beforeEach((to,from,next)=>{
document.title =`${to.meta.title}`;
next();
});

export default router;