<template>
  <div>
    <head>
      <title>Arete-Group</title>
  </head>
  <body>
  <!-- Navigation -->
    <div class="container-fluid">
        <nav class="navbar navbar-expand-lg navbar-light fixed-top navbar-custom">
            <div class="container-fluid">
                <a class="navbar-brand" href="#">
                    <img class="areteidcolhori-sk-1-icon logosize" alt="Arete Logo" src="./assets/img/logo.png"></a>
    
                <button class="navbar-toggler" type="button" id="sidebarToggle" @click="toggleSidebar">
                    <span class="navbar-toggler-icon"></span>
                </button>
    
                <div class="collapse navbar-collapse" id="navbarNav">
                    <ul class="navbar-nav ms-auto">
                        <li class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle" href="#" id="ourBusinessesDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                Explore
                            </a>
                            <ul class="dropdown-menu" aria-labelledby="ourBusinessesDropdown">
                              <li><router-link class="dropdown-item" to="/about">About</router-link></li>
                              <li><router-link class="dropdown-item" to="/values">Values</router-link></li>
                              <li><router-link class="dropdown-item" to="/csr">CSR</router-link></li>
                            </ul>
                        </li>

                        <li class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle" href="#" id="ourBusinessesDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                Our Businesses
                            </a>
                            <ul class="dropdown-menu" aria-labelledby="ourBusinessesDropdown">
                                <li><router-link class="dropdown-item" to="/industrial-parks">Industrial Parks</router-link></li>
                                <li><router-link class="dropdown-item" to="/RealEstate">Real Estate</router-link></li>
                                <li><router-link class="dropdown-item" to="/Hospitality">Hospitality</router-link></li>
                                <li><router-link class="dropdown-item" to="/retail">Retail</router-link></li>
                                <li><router-link class="dropdown-item" to="/investments">Investments</router-link></li>
                            </ul>
                        </li>

                        <li class="nav-item"><router-link class="dropdown-item navcolor" to="/Life-at-Arete">Life at Arete</router-link></li>
                        <li class="nav-item "><router-link class="dropdown-item navcolor" to="/media">Media</router-link></li>
                        <li class="nav-item "><router-link class="dropdown-item navcolor" to="/Contactus">Contact Us</router-link></li>

                    </ul>
                </div>
            </div>
        </nav>
    
        <!-- Sidebar -->
        <div class="sidebar mainbg" id="sidebar" :class="{ active: isActive }" >
            <button class="ot-menu-toggle menu-backdrop" id="closeSidebar" @click="toggleSidebar" ><i class="fa fa-close"></i></button>
            <ul >
                <li class="nav-item dropdown">
                    <a class="nav-link text-white dropdown-toggle" href="#" id="ourBusinessesDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                        Explore
                    </a>
                    <ul class="dropdown-menu" aria-labelledby="ourBusinessesDropdown" @click="toggleSidebar">
                        <li><router-link class="dropdown-item" to="/about">About</router-link></li>
                              <li><router-link class="dropdown-item" to="/values">Values</router-link></li>
                              <li><router-link class="dropdown-item" to="/csr">CSR</router-link></li>
                    </ul>
                </li>

                <li class="nav-item dropdown">
                    <a class="nav-link text-white dropdown-toggle" href="#" id="ourBusinessesDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                        Our Businesses
                    </a>
                    <ul class="dropdown-menu" aria-labelledby="ourBusinessesDropdown" @click="toggleSidebar">
                        <li><router-link class="dropdown-item" to="/industrial-parks">Industrial Parks</router-link></li>
                        <li><router-link class="dropdown-item" to="/RealEstate">Real Estate</router-link></li>
                        <li><router-link class="dropdown-item" to="/Hospitality">Hospitality</router-link></li>
                        <li><router-link class="dropdown-item" to="/retail">Retail</router-link></li>
                        <li><router-link class="dropdown-item" to="/investments">Investments</router-link></li>
                    </ul>
                </li>
                <li class="nav-item "><router-link class="nav-link text-white" @click="toggleSidebar" to="/Life-at-Arete">Life at Arete</router-link></li>
                <li class="nav-item "><router-link class="nav-link text-white" @click="toggleSidebar" to="/media">Media</router-link></li>
                <li class="nav-item "><router-link class="nav-link text-white" @click="toggleSidebar" to="/Contactus">Contact Us</router-link></li>

            </ul>
        </div>
    </div>

    <div class="">
       
      <router-view></router-view>
    </div>
    
      <!-- --------------------footer-------------- -->
      <section>
        <footer class="footer">
            <div class="container">
                <div class="row">
                    <div class="col-sm-6 footer-left">
                        <a href="#" class="footer-logo">
                            <img src="./assets/img/logo-white.png" class="img-fluid logosize" alt="Arete Logo">
                        </a>
                        <p class="footer-description">
                            Established in 1990, The Arete Group’s journey has been
                            characterised by resilience, sustained growth, and
                            purposeful expansion across multifarious sectors.
                        </p>
                        <div class="footer-social">
                            <a href="https://in.linkedin.com/company/arete-services-pvt-ltd-vapi" target="_blank" rel="noopener" class="social-icon"><i class="fab fa-linkedin-in bgcolor"></i></a>
                            <a href="https://www.youtube.com/@AreteGroupofCompanies"  target="_blank" rel="noopener" class="social-icon"><i class="fab fa-youtube bgcolor"></i></a>
                            <a href="https://www.instagram.com/arete_india/"  target="_blank" rel="noopener" class="social-icon"><i class="fab fa-instagram bgcolor"></i></a>
                            <a href="https://www.facebook.com/profile.php?id=61567374567558" target="_blank" rel="noopener" class="social-icon"><i class="fab fa-facebook-f bgcolor"></i></a>
                            <a href="https://x.com/group_arete" target="_blank" rel="noopener" class="social-icon"><i class="fas fa-times bgcolor"></i></a>
                        </div>
                        <div class="footer-download">
                            <!-- <a href="#" class="download-btn">
                                Download corporate profile <span class="download-icon"><i class="fas fa-download"></i></span>
                            </a> -->
                        </div>
                    </div>
                    <div class="col-sm-6 footer-right">
                        <div class="footer-links">
                            <ul class="footer-menu">
                                <li class="footer-title ">Home</li>
                                <li><router-link class="dropdown-item" to="/Life-at-Arete">Life at Arete</router-link></li>
                                <li><router-link class="dropdown-item" to="/media">Media</router-link></li>
                                <li><router-link class="dropdown-item" to="/Contactus">Contact Us</router-link></li>

                            </ul>
                            <ul class="footer-menu">
                                <li class="footer-title ">Explore</li>
                                <li><router-link class="dropdown-item" to="/about">About</router-link></li>
                                <li><router-link class="dropdown-item" to="/values">Values</router-link></li>
                                <li><router-link class="dropdown-item" to="/csr">CSR</router-link></li>
                            </ul>
                            <ul class="footer-menu">
                                <li class="footer-title ">Our Businesses</li>
                                <li><router-link class="dropdown-item" to="/industrial-parks">Industrial Parks</router-link></li>
                                <li><router-link class="dropdown-item" to="/RealEstate">Real Estate</router-link></li>
                                <li><router-link class="dropdown-item" to="/Hospitality">Hospitality</router-link></li>
                                <li><router-link class="dropdown-item" to="/retail">Retail</router-link></li>
                                <li><router-link class="dropdown-item" to="/investments">Investments</router-link></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="footer-bottom">
                <p>© 2024 <span class="bgcolor">Arete Group</span>. All rights reserved.</p>
                <p>
                  <span>Legal Disclaime</span> <span class="dot">●</span>
                  <router-link to="/PrivacyPolicy">Privacy</router-link>
                </p>
                
            </div>
        </footer>
    </section>

    <a v-if="isVisible"  @click="scrollToTop" class="go-up" aria-label="Scroll to top">
      <marquee direction="up" style="height: 48px;"><i class="fa fa-angle-double-up" aria-hidden="true"></i></marquee>
      
    </a>

  </body>
    </div>
</template>

<script>
import AOS from 'aos';

export default {
  name: 'App',
  inheritAttrs: false,

  data() {
    return {
      isVisible: false,
      isActive: false, // Track whether the sidebar is active
      Search_Item: '',
      loader: false,
      checklogin: this.$store.state.isLoggedIn,
      sessioncheckdata: '',
      usersdata: '',
      role: '',
      username: localStorage.getItem('Username'),
      checkVEG: localStorage.getItem('Homepage'),
    };
  },

  mounted() {
    this.initializeAOS();
    window.addEventListener("scroll", this.toggleVisibility);
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.toggleVisibility);
  },
  watch: {
    $route() {
      // Refresh AOS when route changes
      this.$nextTick(() => {
        AOS.refreshHard();
      });
    },
  },

  methods: {
    toggleVisibility() {
      this.isVisible = window.scrollY > 300;
    },
    scrollToTop() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    toggleSidebar() {
      this.isActive = !this.isActive; // Toggle the sidebar active class
    },
    initializeAOS() {
      // Initialize AOS and refresh it after the component is mounted
         AOS.init({
            duration: 1500,
            easing: 'ease-in-out',
            once: true,
            mirror: false
            });


      this.$nextTick(() => {
        AOS.refresh(); // Refresh AOS when the component is mounted
      });
      // Listen for window load and resize to refresh AOS
      window.addEventListener('load', AOS.refresh);
      window.addEventListener('resize', AOS.refresh);

      // Listen for animationend event to handle layout adjustment
      const elements = document.querySelectorAll('.your-element');
      elements.forEach(el => {
        el.addEventListener('animationend', () => {
          // Remove or adjust space after animation completes
          el.style.marginBottom = '0'; // Adjust this based on your needs
        });
      });
    }


  },
};
</script>

<style>
/* Add any component-specific styles here */
.navcolor{
    color: rgba(0, 0, 0, .55);
}

.go-up {
    position: fixed;
    bottom: 1.5rem;
    right: 1rem;
    font-size: 1.8rem;
    /* background: #92bf25; */
    background: linear-gradient(45deg, #92bf25, #4CAF50); /* Example colors */
    padding: 0.4rem;
    color: #fff;
    border-radius: 5px;
    z-index: 99999;
    line-height: 0;
    display: block;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    height: 45px;
    cursor: pointer;
}

.go-up:hover{
  color: white;
}

</style>
