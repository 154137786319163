<template>
  <div>
     <!-- Banner Section -->
     <section class="banner d-flex align-items-center justify-content-center cleartx">
        <div class="container-fluid">
            <img src="../assets/img/CaseStudies/CaseStudyBg.png" class="img" alt="Life at Arete">
            <div class="container csrbanner">
                <h2 class="csrbannertitle" data-aos="fade-up">Case Studies</h2>
            </div>
        </div>
    </section>

        <section class="cleartx mt-5">
            <div class="container">
                <div class="row">
                    <!-- Card 1 -->
                    <div class="col-md-6">
                        <div class="card border-0" @click="casestudies('FortuneHotel')">
                            <img src="../assets/img/CaseStudies/ITC_Fortune.jpg" class="card-img-top"
                                alt="Media topic">
                            <div class="card-body border-0">
                                <button type="button"
                                    class="btn btn-outline-dark rounded-pill custom-badge-load">25 th October, 2024</button>
                                <h5 class="card-title mt-4"><b>ITC Fortune Hotel</b></h5>
                            </div>
                        </div>
                    </div>
                    <!-- Card 2 -->
                    <div class="col-md-6">
                        <div class="card border-0" @click="casestudies('Galaxy')">
                            <img src="../assets/img/CaseStudies/GHS.png" class="card-img-top"
                                alt="Media topic">
                            <div class="card-body border-0">
                                <button type="button"
                                    class="btn btn-outline-dark rounded-pill custom-badge-load">25 th October, 2024</button>
                                <h5 class="card-title mt-4"><b>Galaxy High Street</b></h5>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-6 mt-5">
                        <div class="card border-0" @click="casestudies('LegendSquare')">
                            <img src="../assets/img/CaseStudies/LegendSquare.jpg" class="card-img-top"
                                alt="Media topic">
                            <div class="card-body border-0">
                                <button type="button"
                                    class="btn btn-outline-dark rounded-pill custom-badge-load">25 th October, 2024</button>
                                <h5 class="card-title mt-4"><b>Legend Square</b></h5>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-6 mt-5">
                        <div class="card border-0" @click="casestudies('Moda')">
                            <img src="../assets/img/CaseStudies/Moda.jpg" class="card-img-top"
                                alt="Media topic">
                            <div class="card-body border-0">
                                <button type="button"
                                    class="btn btn-outline-dark rounded-pill custom-badge-load">25 th October, 2024</button>
                                <h5 class="card-title mt-4"><b>Moda</b></h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="cleartx connectBgvector3">
        <div class="container">
            <div class="row">
                <div class="col-md-6 mb-4">
                    <div class="industrialHeading">
                        <h3 class="text-uppercase breadcrumbs maintitle text-whiteGet" data-aos="fade-up">/ Get in Touch <span class="green-dot"></span> </h3>
                        <br>
                        <h2 class="industrialTitle-connect" data-aos="fade-up">Connect With Us</h2>
                        <br>
                        <p class="industrialdescription text-white" data-aos="fade-up">Discover how Arete can support your business objectives and drive your success in the industrial sector. </p>
                        <p class="industrialdescription text-white" data-aos="fade-up">Reach out to us today to learn more about our services and how we can assist you in your journey.</p>
                        <br>
                        <router-link to="/Contactus">
                            <a id="brand-link" data-aos="fade-left" class="view-website-btn text-decoration-none mt-3 border-white text-white" >Contact Us</a>
                        </router-link>
                    </div>
                  </div>
                  <div class="col-sm-6  d-flex justify-content-end p-2">
                    <img src="../assets/img/CaseStudies/vect.png" class="img-fluid" alt="industialpark" data-aos="fade-right" srcset="">
                </div>
                
            </div>
        </div>
     </section>

    
  </div>
</template>

<script>
export default {
  components: {},
  setup() {
    return {};
  },
  data() {
    return {
      // Add your data properties here
    };
  },
  methods: {
    casestudies(id) {

      this.$router.push({ name: 'CaseStudiesDetails',params: {id:id},query: {q1: id}});

    }
  }
};
</script>


<style>

</style>