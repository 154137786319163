<template>
    <div>
    <!-- Banner Section -->
     <section class="banner d-flex align-items-center justify-content-center cleartx">
        <div class="container-fluid">
            <img src="../assets/img/retail/hoomesection.webp" class="img" alt="Retail">
            <div class="container csrbanner">
                <h2 class="csrbannertitle" data-aos="fade-up">Retail</h2>
            </div>
        </div>
    </section>

        <section class="cleartx retailhomeBg ">
        <div class="container">
            <div class="row g-4 mt-2 mt-md-0">
                <div class="col-sm-6 p-sm-0">
                    <h3 class="text-uppercase title indexheader breadcrumbs maintitle" data-aos="fade-up">/  RETAIL<span class="green-dot"></span> </h3>
                    <br>
                    <h4 class="retailTitle" data-aos="fade-up">
                        Arete: A commitment to excellence and customer-centric innovation</h4>
                </div>
                <div class="col-sm-6 ">
                  <p class="retaildescription" data-aos="fade-up">At Arete, our Retail Division is founded on the principle of customer-obsession. We endeavour to deliver an unparalleled shopping experience, every single time. We leverage advanced data analytics to curate an optimal assortment of brands tailored to meet the needs of diverse customer segments.</p>
                  <p class="retaildescription" data-aos="fade-up">This strategic approach enables us to provide our franchise partners with expert guidance on maintaining an ideal inventory mix, ensuring that customer demands are met with efficiency and precision.</p>
                </div>
            </div>
        </div>
    </section>

    <section class="cleartx">
        <div class="container">
            <div class="row">
                <div class="col-sm-5 p-sm-0">                    
                    <img src="../assets/img/retail/Retail-track.webp" data-aos="fade-left" class="img-fluid w-100" alt="Responsive image">
                </div>
                <div class="col-sm-auto" style="width: 6.166%;"></div>
                    <div class="col-sm-6 p-sm-0  mt-3 mt-md-0">
                    <h4 class="retailTitle" data-aos="fade-up">A Proven Track Record</h4>
                    <p class="retaildescription" data-aos="fade-up">
                        With over 14 years of industry expertise, we have established a proven track record of enabling our franchise partners to achieve heightened foot traffic, improved inventory turnover, and enhanced profitability. </p>
                   
                    <p class="retaildescription" data-aos="fade-up"> Our retail establishments in Gujarat have consistently demonstrated exceptional performance, with 82000 sq. ft. on lease.</p>
                    <p class="retaildescription" data-aos="fade-up">
                        This remarkable success underscores our unwavering commitment to excellence and our capability to cultivate thriving retail environments that yield substantial benefits for both our franchise partners and their clientele.
                    </p>
                    <div class="card-group mt-5">
                        <div class="card border-0">
                            <div class="card-body count-up" data-aos="fade-up">
                                <h5 class="card-title home-second-section-title counter-count float-start">14</h5>
                                <span class="card-title home-second-section-title">&nbsp;</span>
                                <p class="card-text mt-2" data-aos="fade-up">years of industry expertise</p>
                            </div>
                        </div>
                        <div class="slant-line-index"></div>
                        <div class="card border-0">
                            <div class="card-body count-up" data-aos="fade-up">
                                <h5 class="card-title home-second-section-title counter-count float-start">82,000</h5>
                                <span class="card-title home-second-section-title">&nbsp;</span>
                                <p class="card-text mt-2" data-aos="fade-up">square foot retail on lease</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    

    <section class="cleartx">
        <div class="container">
            <div class="row">
                <div class="media-coverage" data-aos="fade-up">
                    <h3 class="text-uppercase title indexheader breadcrumbs maintitle">/ GLOBAL BRANDS<span class="green-dot"></span> </h3>
                    <br>
                </div>

                <swiper :slidesPerView="2" 
                    :spaceBetween="40"
                    :centeredSlides="true"
                    :autoplay="{
                        delay: 2500,
                        disableOnInteraction: false,
                    }"
                    :pagination="false"
                    :navigation="false"
                    :modules="modules"
                    class="mySwiper"
                    loop="true"
                    :breakpoints="{
                    640: {
                        slidesPerView: 2,
                    },
                    768: {
                        slidesPerView: 3,
                    },
                    1024: {
                        slidesPerView: 6,
                    }
                    }"
                    >
                    <swiper-slide><div class="unique-slide"><div class="unique-card"><img src="../assets/img/Home/Partners/1.png" alt="The Economic Times" /></div></div></swiper-slide>
                    <swiper-slide><div class="unique-slide"><div class="unique-card"><img src="../assets/img/Home/Partners/2.png" alt="The Economic Times" /></div></div></swiper-slide>
                    <swiper-slide><div class="unique-slide"><div class="unique-card"><img src="../assets/img/Home/Partners/6.png" alt="The Economic Times" /></div></div></swiper-slide>
                    <swiper-slide><div class="unique-slide"><div class="unique-card"><img src="../assets/img/Home/Partners/7.png" alt="The Economic Times" /></div></div></swiper-slide>
                    <swiper-slide><div class="unique-slide"><div class="unique-card"><img src="../assets/img/Home/Partners/9.png" alt="The Economic Times" /></div></div></swiper-slide>
                    <swiper-slide><div class="unique-slide"><div class="unique-card"><img src="../assets/img/Home/Partners/101.png" alt="The Economic Times" /></div></div></swiper-slide>
                    <swiper-slide><div class="unique-slide"><div class="unique-card"><img src="../assets/img/Home/Partners/102.png" alt="The Economic Times" /></div></div></swiper-slide>
                    <swiper-slide><div class="unique-slide"><div class="unique-card"><img src="../assets/img/Home/Partners/103.png" alt="The Economic Times" /></div></div></swiper-slide>
                </swiper>
            </div>
        </div>
    </section>

    <section class="cleartx py-5">
        <div class="container">
            <div id="contentContainer">
                <div class="row">
                    <h3 class="ouresteemedtitle mb-4 p-sm-0" data-aos="fade-up">Our Esteemed Brands</h3>
                    <!-- Left Content (Text) -->
                   
                            <swiper
                            :modules="modules"
                            loop="true"
                            :autoplay="{ delay: 2500,disableOnInteraction: false, }"
                            :breakpoints="{
                            640: {
                                slidesPerView: 1,
                            },
                            768: {
                                slidesPerView: 1,
                            },
                            1024: {
                                slidesPerView: 1,
                            }
                            }"
                            >
                          

                            <swiper-slide>
                                <div class="row">
                                <div class="col-sm-8 d-flex flex-column justify-content-center p-5" id="content-text" style="background-color: #7ecbdc; color: #333;">
                                    <h2 class="brand-head brandtitle" id="brand-title" data-aos="fade-up">Legend Square</h2>
                                    <p class="brand-para brandDescription mb-5 " id="brand-description" data-aos="fade-up">As a premier brand of Arete, Legend Square has been captivating discerning customers with its exquisite ethnic wear and elegant sarees for over 14 years.</p>
                                    
                                    <a href="https://www.galaxyhighstreet.com/" target="_blank" rel="noopener" id="brand-link" class="view-website-btn mt-3" data-aos="fade-up" style="font-size: 16px; font-weight: 400; line-height: 24px; text-align: left; text-decoration: none;">Visit Website</a>

                                </div>
                                <div class="col-sm-4 d-flex " data-aos="fade-up" style="background-color: #fff;">
                                    <img src="../assets/img/retail/Retail_Legend_Sqaure.png" class="d-block w-100 img-fluid" alt="Slider 1" style="height: 454px;" />
                                </div>
                            </div>
                            
                            </swiper-slide>

                            <swiper-slide>
                                <div class="row">
                                <div class="col-sm-8 d-flex flex-column justify-content-center p-5" id="content-text" style="background-color: #7ecbdc; color: #333;">
                                    <h2 class="brand-head brandtitle" id="brand-title" data-aos="fade-up">Legend Sport</h2>
                                    <p class="brand-para brandDescription mb-5 " id="brand-description" data-aos="fade-up">Legend Sports presents an extensive range of stylish and comfortable sports gear sourced from the world’s leading athleisure brands.</p>
                                    
                                    <a href="https://www.galaxyhighstreet.com/" target="_blank" rel="noopener" id="brand-link" class="view-website-btn mt-3" data-aos="fade-up" style="font-size: 16px; font-weight: 400; line-height: 24px; text-align: left; text-decoration: none;">Visit Website</a>

                                </div>
                                <div class="col-sm-4 d-flex " data-aos="fade-up" style="background-color: #fff;">
                                    <img src="../assets/img/retail/Retail_Legend_Sport.png" class="d-block w-100 img-fluid" alt="Slider 1" style="height: 454px;" />
                                </div>
                            </div>
                            
                            </swiper-slide>
                            
                            <swiper-slide>
                                <div class="row">
                                <div class="col-sm-8 d-flex flex-column justify-content-center p-5" id="content-text" style="background-color: #7ecbdc; color: #333;">
                                    <h2 class="brand-head brandtitle" id="brand-title" data-aos="fade-up">Chere</h2>
                                    <p class="brand-para brandDescription mb-5 " id="brand-description" data-aos="fade-up">Fashion-forward and perpetually on-trend, Chere stands as one of the most distinguished luxury shoe brands to emerge from Gujarat, offering unique and stylish designs that empower women to embrace their individuality. At Arete, we remain steadfast in our mission to redefine the retail landscape, fostering partnerships that drive success and satisfaction for all stakeholders involved.</p>
                                    
                                    <a href="https://chere.in/" target="_blank" id="brand-link" rel="noopener" class="view-website-btn mt-3" data-aos="fade-up" style="font-size: 16px; font-weight: 400; line-height: 24px; text-align: left; text-decoration: none;">Visit Website</a>

                                </div>
                                <div class="col-sm-4 d-flex " data-aos="fade-up" style="background-color: #fff;">
                                    <img src="../assets/img/retail/Retail_Chere.png" class="d-block w-100 img-fluid" alt="Slider 1" style="height: 454px;" />
                                </div>
                            </div>
                            
                            </swiper-slide>

                            </swiper>
                       

                </div>
            </div>
   
            
        </div>
    </section>

        
    </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
// Import Swiper styles
import 'swiper/css';

import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';


export default {
    components: {
      Swiper,
      SwiperSlide,
    },
    setup() {
      return {
        modules: [Autoplay, Pagination, Navigation],
        brandData: [
                {
                  title: 'Chere',
                  description: `Fashion-forward and perpetually on-trend, Chere stands as one of the most distinguished luxury shoe brands to emerge from Gujarat, offering unique and stylish designs that empower women to embrace their individuality. At Arete, we remain steadfast in our mission to redefine the retail landscape, fostering partnerships that drive success and satisfaction for all stakeholders involved.`,
                  image: './img/retail/Retail_Chere.png',
                  link: 'https://chere.in/'
                },
                {
                  title: 'Legend Square',
                  description: 'As a premier brand of Arete, Legend Square has been captivating discerning customers with its exquisite ethnic wear and elegant sarees for over 14 years.',
                  image: './img/retail/Retail_Legend_Sqaure.png',
                  link: 'https://www.galaxyhighstreet.com/'
                },
                {
                  title: 'Legend Sport',
                  description: 'Legend Sports presents an extensive range of stylish and comfortable sports gear sourced from the world’s leading athleisure brands.',
                  image: './img/retail/Retail_Legend_Sport.png',
                  link: 'https://www.galaxyhighstreet.com/'
                }
              ]
      };
    },
};

</script>

<style scroped>
.responsive {
    max-width: 100%;
    height: auto;
}
</style>