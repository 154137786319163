<template>
  <div>
     <!-- Banner Section -->
     <section class="banner d-flex align-items-center justify-content-center cleartx">
        <div class="container-fluid">
            <img src="../assets/img/Media/socialmediadetail.png" class="img" alt="csr">
            <div class="container csrbanner">
                <h2 class="csrbannertitle" data-aos="fade-up">Social Media</h2>
            </div>
        </div>
    </section>

    <section class="cleartx">
        <div class="container">
                <div class="row mb-4">
                    <div class="col-md-6">
                        <h2 class="mediaTitle">Follow, like and share!</h2>
                    </div>
                    <div class="col-md-6 d-flex justify-content-end align-items-center">
                        
                    </div>
                </div>
                <div class="row">
                        <div class="col-sm-12">
                            <div class="elfsight-app-a3c02b70-a69d-4763-a3ba-43d75cb71aa1" data-elfsight-app-lazy></div>
                        </div>
                </div>
        </div>
    </section>



  </div>
</template>

<script>
export default {
  mounted() {
    // Dynamically add the Elfsight platform script
    const script = document.createElement("script");
    script.src = "https://static.elfsight.com/platform/platform.js";
    script.async = true;
    document.body.appendChild(script);
  },
};
</script>

<style>

</style>