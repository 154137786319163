<template>
  <div>
       <!-- Banner Section -->
       <section
            class="banner d-flex align-items-center justify-content-center cleartx">
            <div class="container-fluid">
                <img src="../assets/img/CaseStudies/Blog.jpg" class="img" alt="csr">
                <div class="container csrbanner">
                    <h2 class="csrbannertitle">Blogs</h2>
                </div>
            </div>
        </section>

        <section class="cleartx mt-5">
            <div class="container">
                <div class="row">
                    <!-- Card 2 -->
                    <div class="row">
                        <!-- Card 1 -->
                        <div class="col-md-4">
                            <div class="card border-0" @click="blogsDetails()">
                                <img src="../assets/img/CaseStudies/blogpic.png" class="card-img-top"
                                    alt="Media topic">
                                <div class="card-body border-0">
                                    <button type="button"
                                        class="btn btn-outline-dark rounded-pill custom-badge-load">25 th October, 2024</button>
                                    <h5 class="card-title mt-3">Rooted in Legacy, Designed for Tomorrow:</h5>
                                    <p>Founded in the year 1990, Arete’s journey has been paved by sustained growth and strategic diversification across....</p>
                                    <span class="text-decoration">Read more</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
  </div>
</template>

<script>
export default {
  name: "MainBlogsComponent",
  methods: {
    blogsDetails() {
      this.$router.push({ name: 'MainblogsDetails'});
    }
  }
};
</script>

<style>

</style>