<template>
    <div>

        <!-- Banner Section -->
        <section class="banner d-flex align-items-center justify-content-center cleartx">
            <div class="container-fluid">
                <img src="../assets/img/realstate/realstateHome.webp" class="img" alt="csr">
                <div class="container csrbanner">
                    <h2 class="investmentBannertitle" data-aos="fade-up">Real Estate</h2>
                </div>
            </div>
        </section>

        <section class="cleartx">
            <div class="container">
                <div class="row d-flex flex-column-reverse-mobile">
                    <div class="col-sm-5  d-flex justify-content-start p-3">
                        <img src="../assets/img/realstate/Vector.webp" data-aos="fade-down" class="img-fluid" alt="" srcset="">
                    </div>
                    <div class="col-md-7 mt-2" data-aos="fade-up">
                        <h2 class="realstatetitle">Arete: Transforming Communities Through Premium Real Estate
                            Developments</h2>
                        <p class="realstatedescription">At Arete, our Real Estate Division is at the forefront of
                            developing premium commercial and residential projects meticulously designed to enhance
                            community landscapes. </p>
                        <p class="realstatedescription">Each initiative is preceded by a comprehensive evaluation phase,
                            enabling us to identify both the articulated and latent needs of the target demographic.
                            This strategic approach ensures that our projects are optimally aligned with the
                            requirements of the community, thereby unlocking substantial potential for success.</p>
                    </div>
                </div>
            </div>
        </section>

        <section class="cleartx">
        <div class="container mb-5 ">
               <div class="row mb-5 mb-md-2"> <h2 class="realstatetitle-Commercial" data-aos="fade-up">Commercial Project</h2>
               </div> 
               <!-- -----------first section -->
       
            <div class="row  mb-2">
                <!-- Text Box -->
                <div class="col-sm-7 mb-2 mb-md-0">
                    <div class="commercialbg  p-2 p-md-5">
                        <p class="realstatedescription" data-aos="fade-down">
                            Our commercial real estate portfolio is prominently anchored by the Galaxy High Street Mall. This development stands as a response to the burgeoning aspirations of Vapi, featuring over 20+ leading national and international retail brands.
                        </p><br>
                        <p class="realstatedescription" data-aos="fade-up">
                            Galaxy High Street Mall delivers a modern, world-class shopping, entertainment, and leisure experience, establishing itself as the premier shopping destination for the residents of Vapi and its surrounding areas.
                        </p>
                    </div>
                </div>
                 <!-- Image Column -->
                 <div class="col-sm-5 border">
                    <div class="border-custome  h-100 w-100 d-flex align-items-center justify-content-center">
                        <img src="../assets/img/realstate/galaxy.png" class="img-fluid" alt="Media topic" data-aos="fade-up" style="object-fit: cover;">
                    </div>
                </div>
                
            </div>
       
            <div class="row  mb-2 d-flex flex-column-reverse-mobile">
                <!-- Image Column -->
                <div class="col-sm-6  mb-2">
                    <div class="border h-100 " data-aos="fade-up">
                        <img src="../assets/img/realstate/chere.webp" class="img-fluid h-100 w-100" alt="Media topic" style="object-fit: cover;">
                    </div>
                </div>
                <!-- Text Box -->
                <div class="col-sm-6 d-flex align-items-center commercialbg2  p-5 mb-2">
                    <div>
                        <p class="realstatedescription" data-aos="fade-up">
                            The mall has significantly contributed to enhancing the quality of life, fostering economic growth, and generating substantial employment opportunities within the region.
                        </p>
                        <br>
                        <a href="https://galaxyhighstreet.com/" data-aos="fade-up" target="_blank" rel="noopener" id="brand-link" class="view-website-btn mt-3" style="font-size: 16px; font-weight: 400; line-height: 24px; text-align: left; text-decoration: none;">Visit Website</a>
                    </div>
                </div>  
            </div>
        </div>
    </section>

    <section class="cleartx">
        <div class="container">
            <div class="row ">
                <div class="col-sm-6 d-flex align-items-center" >
                    <div>
                        <h4 class="realstatetitle-upcoming mb-4" data-aos="fade-up">
                            Upcoming Residential Project
                        </h4>
                        <p class="realstatedescription" data-aos="fade-down">
                            In the realm of residential real estate, we are proud to present Riverscape, an upcoming luxury riverfront villa community development spanning over 200 acres in Vapi. This project epitomizes our commitment to creating exceptional living environments that cater to the discerning tastes of our clientele.
                        </p>
                        <p class="realstatedescription" data-aos="fade-up">
                            At Arete, we remain dedicated to our mission of developing projects that not only meet the needs of today but also anticipate the aspirations of tomorrow.                        </p>
                    </div>
                </div>
                    <div class="col-sm-6 d-flex align-items-center justify-content-center">
                        <img src="../assets/img/realstate/Residential.webp" data-aos="fade-up" alt="Group of people promoting mental health" class="img-fluid h-100 w-100" style="object-fit: cover;">
                    </div>
            </div>

        </div>
    </section>

    </div>
</template>

<script lang="ts" setup>

</script>

<style scroped>
.responsive {
    max-width: 100%;
    height: auto;
}
.realstatetitle-upcoming{
    font-size: 42px;
    font-weight: 500;
    line-height: 50.83px;
    text-align: left;
    color: #0F557B;
    }
</style>