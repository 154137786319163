/* eslint-disable no-console */
import { register } from 'register-service-worker'

if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready() {
      console.log(
        'App is being served from cache by a service worker.\n' +
        'For more details, visit https://goo.gl/AFskqB'
      )
    },
    registered() {
      console.log('Service worker has been registered.')
    },
    cached() {
      console.log('Content has been cached for offline use.')
    },
    updatefound() {
      console.log('New content is downloading.')
    },
    updated() {
      console.log('New content is available; please refresh.')
    },
    offline() {
      console.log('No internet connection found. App is running in offline mode.')
    },
    error(error) {
      console.error('Error during service worker registration:', error)
    }
  })
}

// Function to show update notification
// function showUpdateNotification() {
//   // Create a simple toast/banner or modal element to notify the user
//   const updateBanner = document.createElement('div')
//   updateBanner.innerHTML = `
//     <div style="position: fixed; bottom: 0; width: 100%; background: #f39c12; color: white; text-align: center; padding: 10px;">
//       New content is available! <button style="background: #27ae60; color: white; padding: 5px 10px; margin-left: 10px; cursor: pointer;" onclick="location.reload()">Refresh</button>
//     </div>
//   `
//   document.body.appendChild(updateBanner)
// }


