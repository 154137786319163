import { createStore } from 'vuex';
import axios from 'axios';

const store = createStore({
    state() {
        return {
            base_url: 'https://tmsapipost.areteonline.in/',
            category: [],
            subcategory: [],
            items: [],
            offerlist: [],
            MostPopular: [],
            user: null,  // Make sure to initialize user state
        };
    },
    mutations: {
        setUser(state, payload) {
            state.user = payload;
        },
    },
    actions: {
        async submitdata({ commit }, payload) {
            console.log('------store---', payload);
            const optionAxios = {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            };

            try {
                const res = await axios.post('https://emailservices.areteonline.in/index.php/enquire-email', payload, optionAxios);
                alert('success');
                commit('setUser', res);  // Corrected here
            } catch (error) {
                // handle error
                console.log(error);
            } finally {
                // always executed
                console.log('--------s-');
            }
        },
        async submitMediaKit({ commit }, payload) {
            console.log('------store---', payload);
            const optionAxios = {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            };

            try {
                const res = await axios.post('https://emailservices.areteonline.in/index.php/mediaKit-email', payload, optionAxios);
                alert('success');
                commit('setUser', res);  // Corrected here
            } catch (error) {
                // handle error
                console.log(error);
            } finally {
                // always executed
                console.log('--------s-');
            }
        },
    },
    getters: {
        // Add any getters if needed
    }
});

export default store;
