<template>
  <div>
    
    <!-- Banner Section -->
     <section class="banner d-flex align-items-center justify-content-center cleartx">
        <div class="container-fluid">
            <img src="../assets/img/values/valuesBanner.webp" class="img" alt="csr">
            <div class="container csrbanner">
                <h2 class="csrbannertitle" data-aos="fade-up">Values</h2>
            </div>
        </div>
    </section>


    <section class="mt-4">
        <div class="container">
            <div class="row">           
                <div class="col-sm-12 mt-5">
                    <br>
                    <h3 class="text-uppercase title indexheader breadcrumbs maintitle" data-aos="fade-up">/  OUR VALUES<span class="green-dot"></span> </h3>
                    <br>
                    <p class="valuedescription" data-aos="fade-up">Arete is an organisation made up of diverse individuals brought together by a set of core values that serve as our guiding light. These values are not just words on paper, they are the very foundation of our culture, shaping every decision and action we take.</p>
                </div>
            </div>
        </div>
    </section>

    <section>
        <div class="container mt-5 d-md-flex align-items-stretch justify-content-center">
            <div class="row d-flex align-items-stretch">
                <!-- Card Section -->
                <div class="col-lg-4 col-md-6 col-sm-12   d-md-flex mb-2 mb-md-0">
                    <div class="card custom-card d-flex flex-column justify-content-between p-3 our-values-bgborder"> 
                        <img class="card-img-top mb-3" data-aos="fade-left" src="../assets/svg/Values_1.svg" style="height:114px; width:114px; padding-top:20px;">
                        <div class="card-body">
                            <h5 class="card-title mb-4 valuetitle" data-aos="fade-up">The Quest for Excellence</h5>
                            <p class="card-text" data-aos="fade-up">The spirit of Excellence reigns supreme at Arete. Team members don’t just aim to meet expectations; they seek to surpass them. They set ambitious benchmarks, pushing the boundaries of what is possible. </p>
                            <p class="card-text" data-aos="fade-up">Each project becomes a canvas for their creativity and dedication, and they take immense pride in their work. This relentless pursuit of excellence inspires everyone around them, creating a ripple effect that motivates each individual to strive for their best.</p>
                        </div>
                    </div>
                </div>
                <!-- Image Section -->
                <div class="col-lg-8 col-md-6 col-sm-12 d-flex">
                    <div class="d-flex align-items-center w-100">
                        <img src="../assets/img/values/Excellence.webp" data-aos="fade-right" class="img-fluid w-100 h-100" style="object-fit: cover;" alt="Excellence Image">
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section>
        <div class="container mt-5 d-md-flex align-items-stretch justify-content-center">
            <div class="row d-flex align-items-stretch flex-column-reverse-mobile">
                 <!-- Image Section -->
                 <div class="col-lg-8 col-md-6 col-sm-12 d-md-flex mb-4 mb-sm-0 ">
                    <div class="d-flex align-items-center w-100">
                        <img src="../assets/img/values/Empowerment.webp" data-aos="fade-left" class="img-fluid w-100 h-100" style="object-fit: cover;" alt="Empowerment Image">
                    </div>
                </div>
                <!-- Card Section -->
                <div class="col-lg-4 col-md-6 col-sm-12   d-md-flex mb-2 mb-md-0">
                    <div class="card custom-card d-flex flex-column justify-content-between p-3 our-values-bgborder-1">
                        <img class="card-img-top mb-3" src="../assets/svg/Values_2.svg" data-aos="fade-right" style="height:114px; width:114px; padding-top:20px;">
                        <div class="card-body">
                            <h5 class="card-title mb-4 valuetitle" data-aos="fade-up">The Power of Empowerment</h5>
                            <p class="card-text" data-aos="fade-up">As the organisation flourishes, it is clear to us that Empowerment is key to unlocking potential. Leaders encourage teams to take ownership of their decisions, fostering an environment where autonomy thrives. </p>
                            <p class="card-text" data-aos="fade-up">Every member, regardless of their role, feels a sense of accountability for their contributions. This culture of empowerment has transformed the workplace into a dynamic space where ideas flow freely, and innovation blossoms.</p>    
                        </div>
                    </div>
                </div>
               
            </div>
        </div>
    </section>

    <section>
        <div class="container mt-5 d-md-flex align-items-stretch justify-content-center">
            <div class="row d-flex align-items-stretch">
                
                <!-- Card Section -->
                <div class="col-lg-4 col-md-6 col-sm-12   d-md-flex mb-2 mb-sm-0 ">
                    <div class="card custom-card d-flex flex-column justify-content-between p-3 our-values-bgborder-2">
                        <img class="card-img-top mb-3" src="../assets/svg/Values_3.svg" data-aos="fade-left" style="height:114px; width:114px; padding-top:20px;">
                        <div class="card-body">
                            <h5 class="card-title mb-4 valuetitle" data-aos="fade-up">With Empathy at Heart</h5>
                            <p class="card-text" data-aos="fade-up">Even as we pursue excellence, we make sure to never lose sight of Empathy. Because we understand that a supportive and inclusive environment is essential for true collaboration. </p>
                            <p class="card-text" data-aos="fade-up">An environment where team members actively listen to one another, and offer help and recognition. This culture of care has created a safe haven where everyone feels valued, enhancing morale and strengthening bonds.</p>    
                        </div>
                    </div>
                </div>
                 <!-- Image Section -->
                 <div class="col-lg-8 col-md-6 col-sm-12 d-flex">
                    <div class="d-flex align-items-center w-100">
                        <img src="../assets/img/values/Empathy.webp" class="img-fluid w-100 h-100" data-aos="fade-right" style="object-fit: cover;" alt="Empathy Image">
                    </div>
                </div>
               
            </div>
        </div>
    </section>

    <section class="mb-5">
        <div class="container mt-5 d-md-flex align-items-stretch justify-content-center">
            <div class="row d-flex align-items-stretch flex-column-reverse-mobile">
                  <!-- Image Section -->
                  <div class="col-lg-8 col-md-6 col-sm-12 d-md-flex mb-2 mb-sm-0">
                    <div class="d-flex align-items-center w-100">
                        <img src="../assets/img/values/Resilience.webp" data-aos="fade-left" class="img-fluid w-100 h-100" style="object-fit: cover;" alt="Resilience  Image">
                    </div>
                </div>
                <!-- Card Section -->
                <div class="col-lg-4 col-md-6 col-sm-12   d-md-flex mb-2 mb-md-0">
                    <div class="card custom-card d-flex flex-column justify-content-between p-3 our-values-bgborder-3">
                        <img class="card-img-top mb-3" src="../assets/svg/Values_4.svg" data-aos="fade-up" style="height:114px; width:114px; padding-top:20px;">
                        <div class="card-body">
                            <h5 class="card-title mb-4 valuetitle" data-aos="fade-up">The Strength of Resilience</h5>
                            <p class="card-text" data-aos="fade-up">Over the years, the organisation has demonstrated remarkable Resilience in the face of challenges. We have embraced change and viewed obstacles as opportunities for growth. </p>
                            <p class="card-text" data-aos="fade-up">With resilience woven into our very fabric, we have tackled problems head-on, fostering a spirit of cooperation and creativity. This adaptability has allowed us to navigate the ever-evolving business landscape with confidence.</p>    
                        </div>
                    </div>
                </div>
               
               
            </div>
        </div>
    </section>
    

    <section class="cleartx bgvaluesLine">
        <div class="container-fluid " >
            <div class="container ">
            <div class="row g-5"> 
                <div class="col-sm-6 col align-self-center me-5" >
                <h2 class="values-section-Title" data-aos="fade-up">In Essence</h2><br>
                <p class="values-section-description" data-aos="fade-up">In essence, these core values—Excellence, Empowerment, Empathy, and Resilience—have become the cornerstone of our organisation’s ethos. </p>
                <p class="values-section-description" data-aos="fade-up">They are not just principles, they are the heartbeat of the community, driving everyone toward sustained growth.</p>
                <router-link to="/Contactus">
                    <button type="button" class="btn btn-outline-dark rounded-pill values-badge-load px-5 py-2" data-aos="fade-up">Contact Us</button>
                </router-link>    
            </div>
                <div class="col-sm-5 p-2 d-sm-flex align-items-stretch justify-content-end text-center">
                    <img src="../assets/img/values/Vector.webp" alt="img-fluid" srcset="" data-aos="fade-up">
                </div>
            </div>
            </div>
        </div>
    </section>
    
  </div>
</template>

<script lang="ts" setup>

</script>

<style scoped>

.valuetitle{
    font-size: 24px;
    font-weight: 500;
    line-height: 36px;
    text-align: left;
    }

    .valuedescription{
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    text-align: left;
    }

    .valuefirstsection{
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        text-align: left;
    }

    
    .our-values-bgborder{
        border: 1px solid; /* Set the border width */
        border-image: linear-gradient(45deg, rgba(120, 205, 208, 1), rgba(191, 212, 47, 1)) 1;
    }

    .our-values-bgborder:hover{
        background: rgba(191, 212, 47, 1);
     }

     .our-values-bgborder:hover .card-img-top {
        content: url('/src/assets/svg/values_1hover.svg');
        transform: scale(1.3);
    }

    .our-values-bgborder-1{
        border: 1px solid; /* Set the border width */
        border-image: linear-gradient(45deg, rgba(120, 205, 208, 1), rgba(191, 212, 47, 1)) 1;
    }

    .our-values-bgborder-1:hover{
        background: rgba(191, 212, 47, 1);
     }

     .our-values-bgborder-1:hover .card-img-top {
        content: url('/src/assets/svg/Values_2hover.svg');
        transform: scale(1.3);
    }

    .our-values-bgborder-2{
        border: 1px solid; /* Set the border width */
        border-image: linear-gradient(45deg, rgba(120, 205, 208, 1), rgba(191, 212, 47, 1)) 1;
    }

    .our-values-bgborder-2:hover{
        background: rgba(191, 212, 47, 1);
     }

     .our-values-bgborder-2:hover .card-img-top {
        content: url('/src/assets/svg/Values_3hover.svg');
        transform: scale(1.3);
    }


    .our-values-bgborder-3{
        border: 1px solid; /* Set the border width */
        border-image: linear-gradient(45deg, rgba(120, 205, 208, 1), rgba(191, 212, 47, 1)) 1;
    }

    .our-values-bgborder-3:hover{
        background: rgba(191, 212, 47, 1);
     }

     .our-values-bgborder-3:hover .card-img-top {
        content: url('/src/assets/svg/Values_4hover.svg');
        transform: scale(1.3);
    }




    .bgvaluesLine{
        background: #BFD730;
        background-image: url('/src/assets/img/values/linebg.webp');
        background-size: cover;   
        background-position: center; 
        background-repeat: no-repeat; 
    }
    
    @media (max-width: 600px) {
    .card {
        height: 100%;
    }
}

</style>