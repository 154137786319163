<template>
  <div>
    <!-- Banner Section -->
    <section
      class="banner d-flex align-items-center justify-content-center cleartx">
      <div class="container-fluid">
        <!-- Corrected the v-bind usage for dynamic image src -->
        <img :src="bannerImage" class="img" alt="csr">
        <div class="container csrbanner">
          <h2 class="csrbannertitle">{{ selectedPark.fname }}</h2>
        </div>
      </div>
    </section>

    <section class="cleartx">
            <div class="container">
                <div class="row">
                    <div class="col-sm-12">
                        <div class="profile d-flex justify-content-between align-items-center">
                            <!-- Left section: Profile picture and name -->
                            <div class="profile-text-left d-flex align-items-center">
                            </div>
                            <!-- Right section: Time, Date, Icons -->
                            <div class="profile-text-right d-flex align-items-center">
                                <small class="profile-time mr-4 gx-3">10 mins</small>
                                <small class="profile-date mr-4 gx-3">25 October 2024</small>
                                <span class="share-icon">🔗</span>
                            </div>
                        </div>
                        <!-- Add the vertical line below -->
                        <hr class="divider">
                    </div>
                </div>
            </div>
        </section>

        <section class="cleartx" id="challenge">
            <div class="container">
                <div class="row g-5">
                    <div
                        class="col-sm-5 d-flex flex-column justify-content-center">
                        <img :src="challengeImage" class="img-fluid" alt="Media topic">
                    </div>
                    <div
                        class="col-sm-7 p-5 d-flex flex-column justify-content-center">
                        <h3 class="case-studies-title mb-4" >Challenge:</h3>
                        <p class="case-studies-desc">{{ selectedPark.challengePTag }}</p>
                        <p class="case-studies-desc">{{ selectedPark.challengeP2Tag }}</p>
                    </div>
                </div>
            </div>
        </section>

        <section class="cleartx" id="solution">
            <div class="container">
                <div class="row g-5">
                    <div
                        class="col-sm-7 d-flex flex-column justify-content-center">
                        <h3 class="case-studies-title mb-4">Solution:</h3>
                        <p class="case-studies-desc" style="font-size: 20px; font-weight: 400; line-height: 30px;">{{ selectedPark.SolutionPTag }}</p>
                        <p class="case-studies-desc" style="font-size: 20px; font-weight: 400; line-height: 30px;">{{ selectedPark.SolutionP2Tag }}</p>
                    </div>
                    <div
                        class="col-sm-5 d-flex flex-column justify-content-center">
                        <img :src="SolutionImage"  class="img-fluid" alt="Media topic">
                    </div>
                </div>
            </div>
        </section>

        <section class="cleartx">
            <div class="container">
                <div class="row d-flex flex-column-reverse-mobile">
                    <div class="col-sm-12">
                        <img :src="OutcomeImage"  class="img-fluid" alt="Media topic">
                    </div>
                    <div class="col-sm-12 mt-5">
                        <h3 class="case-studies-title mb-5">Outcome:</h3>
                        <p class="case-studies-desc">{{ selectedPark.OutcomePTag }}</p>
                    </div>
                </div>
            </div>
        </section>

        <section class="cleartx">
            <div class="container mt-5">
                <div class="row mb-4">
                    <div class="col-md-6">
                        <h2 class="mediaTitle">More Case Studies</h2>
                    </div>
                    <div class="col-md-6 d-flex justify-content-end align-items-center">
                        <router-link to="/case-studies">
                          <button type="button" class="btn btn-outline-dark rounded-pill custom-badge-load px-5 py-2">View
                              All</button>
                        </router-link>
                    </div>
                </div>
                <div class="row">
                  <div class="col-md-4">
                        <div class="card border-0">
                            <img :src="CaseStudiesImage" class="card-img-top" alt="Media topic">
                            <div class="card-body border-0">
                                <button type="button"
                                    class="btn btn-outline-dark rounded-pill custom-badge-load px-4">News</button>
                                <button type="button" class="btn btn-outline-dark rounded-pill custom-badge-load">25 October 2024</button>
                                <h5 class="card-title mt-3">{{selectedStudies.fname}}</h5>
                               
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="card border-0">
                            <img :src="CaseStudiesImage1" class="card-img-top" alt="Media topic">
                            <div class="card-body border-0">
                                <button type="button"
                                    class="btn btn-outline-dark rounded-pill custom-badge-load px-4">News</button>
                                <button type="button" class="btn btn-outline-dark rounded-pill custom-badge-load">25 October 2024</button>
                                <h5 class="card-title mt-3">{{selectedStudies.sname}}</h5>
                                
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="card border-0">
                            <img :src="CaseStudiesImage2" class="card-img-top" alt="Media topic">
                            <div class="card-body border-0">
                                <button type="button"
                                    class="btn btn-outline-dark rounded-pill custom-badge-load px-4">News</button>
                                <button type="button" class="btn btn-outline-dark rounded-pill custom-badge-load">25 October 2024</button>
                                <h5 class="card-title mt-3">{{selectedStudies.tname}}</h5>
                               
                            </div>
                        </div>
                    </div>
                    
                </div>
                
            </div>
        </section>

      


  </div>
</template>


<script>
export default {
  components: {},
  setup() {
    return {};
  },
  data() {
    return {
      // Add your data properties here
      id: this.$route.query.q1, //this is the id from the browser
      parks: {
        FortuneHotel: {
          fname:'Fortune Hotel',
          bannerimg: require('../assets/img/CaseStudies/details/FortuneHotel.png'),
          ChallengeSecImg: require('../assets/img/CaseStudies/details/hotelchallege.png'),
          challengePTag: 'In late 1990s, Vapi and its surrounding areas were bustling with industries. Despite this industrial boom, there was a significant gap in high-quality accommodation options for the C-Suite executives visiting the region.',
          challengeP2Tag: 'The lack of suitable lodging posed a challenge for companies looking to host their top executives in an environment that met their standards of comfort and convenience.',
          SolutionSecImg:  require('../assets/img/CaseStudies/details/hosoultion.png'),
          SolutionPTag: 'To address this gap, we embarked on a mission to attract leading hospitality brands (WE HAVE DIRECTLY APPROACHED ITC - first approached got cracked and we got ITC vapi) to Vapi. After evaluating several options, we partnered with ITC to establish Fortune Park Galaxy.',
          SolutionP2Tag: 'Our collaboration with ITC was driven by their proven track record in hospitality and their alignment with our decision to ensure that our guests would receive top-tier services and amenities . By partnering with a reputable brand, we equipped our team with the tools and training needed to deliver excellent accommodation experience & unmatched service quality.',
          OutcomeSecImg:  require('../assets/img/CaseStudies/details/Hoteloutcome.png'),
          OutcomePTag: 'Fortune Park Galaxy quickly became the preferred choice for C-Suite executives visiting Vapi. Today, more than 60% of these high-level professionals choose to stay with us during their business trips. Our hotel has become synonymous with quality and comfort, making it a destination of choice for business travelers. This accomplishment underscores our commitment to providing exceptional service and making a positive impact on the business community in Vapi.',

          description: 'Besides helping with environmental and regulatory clearances, we are also setting up an 80-acre community space 5 km from the park.'
        },
        Galaxy: {
          fname:'Galaxy High Street',
          bannerimg: require('../assets/img/CaseStudies/details/galaxyhigh.jpg'),
          ChallengeSecImg: require('../assets/img/CaseStudies/details/galaxyChallenge.webp'),
          challengePTag: 'In the year [2004], Vapi and its neighboring areas, including Valsad, Daman & Silvassa were experiencing rapid industrial & population growth. However, there was a notable absence of modern shopping centers, creating a gap in lifestyle, dining and recreational facilities for the recently settled workforce in these industries and locals.',
          challengeP2Tag: 'The lack of retail and leisure options presented a significant challenge for the residents and professionals working in the region, impacting their quality of life and limiting their shopping and leisure experiences.',
          SolutionSecImg:  require('../assets/img/CaseStudies/details/GalaxySolution.webp'),
          SolutionPTag: 'To address this gap, we conducted a comprehensive qualitative survey with 100 respondents to understand their preferences for brands and the types of retail and leisure facilities they desired. This survey was instrumental in identifying the needs and preferences of local population.',
          SolutionP2Tag: 'Armed with this valuable insight, we approached and secured partnerships with 20+ leading national & international retail brands [pizza hut , levis , Raymond , Metro -Can insert prominent brand stores GHS has] to bring their presence to Vapi. The aim was to create a vibrant shopping and leisure destination that would enhance the overall living experience in the region.',
          OutcomeSecImg:  require('../assets/img/CaseStudies/details/galaxyGHS.webp'),
          OutcomePTag: 'Galaxy High Street has successfully become a premier destination for shopping and leisure for the people of Vapi, Valsad, and the surrounding areas. The presence of top-tier brands, many of which are not available in other Tier-II cities, has made Galaxy High Street a unique and attractive spot. With its wide range of elegant retail options, fancy dining establishment and exclusive coffee shop to facilitate business meetings, it offers an unparalleled experience. Galaxy High Street is a symbol of our commitment to bringing world-class retail experience to Vapi, fostering economic growth with significant employment opportunities, and providing a welcoming environment for everyone.',

          description: 'Besides helping with environmental and regulatory clearances, we are also setting up an 80-acre community space 5 km from the park.'
        },
        LegendSquare:{
          fname:'Legend Square',
          bannerimg: require('../assets/img/CaseStudies/details/LegendSquare.jpg'),
          ChallengeSecImg: require('../assets/img/CaseStudies/details/Legend1.jpg'),
          challengePTag: 'In 2004, people in Vapi faced a significant challenge regarding the availability of branded and authentic ethnic wear, particularly sarees. ',
          challengeP2Tag: 'Customers often struggled to find genuine products, as many local stores lacked the variety and authenticity desired by tasteful shoppers. This scarcity of authentic ethnic wear led to dissatisfaction and limited shopping options for those seeking quality and variety.',
          SolutionSecImg:  require('../assets/img/CaseStudies/details/legendsquarsoultion.jpg'),
          SolutionPTag: 'We established Legend Square, a self-owned multi-brand outlet, committed to delivering ethnic wear sourced from authentic and reputable suppliers.',
          SolutionP2Tag: 'Our focus was on curating a diverse collection of high-quality products to meet the varied tastes and preferences of our customers {we have 14 brands SIS like - us polo , rare rabbit , Indian terrine , spiker , Arrow etc.. Mention the number of brands and name some of them}',
          OutcomeSecImg:  require('../assets/img/CaseStudies/details/legendsquarM.jpg'),
          OutcomePTag: 'In the last two decades, Legend Square has become a trusted destination for authentic ethnic & casual wear irrespective of gender. The outlet has become synonymous with quality and authenticity, drawing customers from Vapi and surrounding regions. Shopping at Legend Square is more than just a retail experience; it`s an emotional journey that brings a sense of cultural pride. Customers feel a deep connection to their heritage, finding garments that resonate with their personal stories and traditions.',
        },
        Moda:{
          fname:'Moda',
          bannerimg: require('../assets/img/CaseStudies/details/Moda.jpg'),
          ChallengeSecImg: require('../assets/img/CaseStudies/details/Modachallege.png'),
          challengePTag: 'Women in Vapi & neighboring catchments encountered the pervasive challenge of finding comprehensive range of products and services tailored to their needs and preferences under one roof.',
          challengeP2Tag: 'The lack of a female-centric shopping zone not only limited their choices but also hindered their ability to enjoy a convenient and fulfilling shopping experience.',
          SolutionSecImg:  require('../assets/img/CaseStudies/details/Modasoultion.png'),
          SolutionPTag: 'To overcome this challenge, MODA was conceptualized in [Oct - 2020] as a dedicated space exclusively for female shoppers. We curated a collection of brands like BIBA, RITU KUMAR, CHERE and FAB INDIA that cater to women and enhance their lifestyle. ',
          SolutionP2Tag: 'We ensured that all brands featured in the space met high standards of quality, innovation, and customer satisfaction. The essence of ease in shopping was ensured with seamless accessibility to a wide variety of products and services.',
          OutcomeSecImg:  require('../assets/img/CaseStudies/details/Modaoutcome.png'),
          OutcomePTag: 'When a woman shops at MODA, the feeling of connection and camaraderie is witnessed as she shares a knowing glance with another woman. It’s a moment of mutual appreciation for shared taste and style. She feels a sense of belonging to a community that celebrates diversity, creativity, and the beauty of feminine expression. MODA is a space where women not only shop but also connect, inspire and uplift each other through shared experiences and fashion choices.',

        }
      },
      relatedStudies:{
        FortuneHotel: {
          fname:'Galaxy Hight Street',
          sname:'Legend Square',
          tname:'Moda',
          bannerimg1: require('../assets/img/CaseStudies/GHS.png'),
          bannerimg2: require('../assets/img/CaseStudies/LegendSquare.jpg'),
          bannerimg3: require('../assets/img/CaseStudies/Moda.jpg'),
          
        },
        Galaxy: {
          fname:'Fortune Park Galaxy',
          sname:'Legend Square',
          tname:'Moda',
          bannerimg1: require('../assets/img/CaseStudies/ITC_Fortune.jpg'),
          bannerimg2: require('../assets/img/CaseStudies/LegendSquare.jpg'),
          bannerimg3: require('../assets/img/CaseStudies/Moda.jpg'),
        },
        LegendSquare:{
          fname:'Fortune Park Galaxy',
          sname:'Galaxy Hight Street',
          tname:'Moda',
          bannerimg1: require('../assets/img/CaseStudies/ITC_Fortune.jpg'),
          bannerimg2: require('../assets/img/CaseStudies/GHS.png'),
          bannerimg3: require('../assets/img/CaseStudies/Moda.jpg'),
        },
        Moda:{
          fname:'Fortune Park Galaxy',
          sname:'Galaxy Hight Street',
          tname:'Legend Square',
          bannerimg1: require('../assets/img/CaseStudies/ITC_Fortune.jpg'),
          bannerimg2: require('../assets/img/CaseStudies/GHS.png'),
          bannerimg3: require('../assets/img/CaseStudies/LegendSquare.jpg'),
        }
      }



    };
  },
  computed: {
    // Computed property to fetch the correct park based on the id in the query parameter
    selectedPark() {
      return this.parks[this.id] || null; // Fetch park by id, or return null if not found
    },
    bannerImage() {
      return this.selectedPark ? this.selectedPark.bannerimg : ''; // Safely returns the banner image or an empty string
    },
    challengeImage() {
      return this.selectedPark ? this.selectedPark.ChallengeSecImg : ''; // Safely returns the banner image or an empty string
    },
    SolutionImage(){
      return this.selectedPark ? this.selectedPark.SolutionSecImg : ''; // Safely returns the banner image or an empty string
    },
    OutcomeImage(){
      return this.selectedPark ? this.selectedPark.OutcomeSecImg : ''; // Safely returns the banner image or an empty string
    },
    selectedStudies(){
      return this.relatedStudies[this.id] || null; // Fetch park by id, or return null if not found
    },
    CaseStudiesImage() {
      return this.selectedStudies ? this.selectedStudies.bannerimg1 : ''; // Safely returns the banner image or an empty string
    },
    CaseStudiesImage1() {
      return this.selectedStudies ? this.selectedStudies.bannerimg2 : ''; // Safely returns the banner image or an empty string
    },
    CaseStudiesImage2() {
      return this.selectedStudies ? this.selectedStudies.bannerimg3 : ''; // Safely returns the banner image or an empty string
    },

  },
  methods: {
   
  }
};
</script>

<style scoped>
.case-studies-desc{
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
}

.case-studies-title{
  font-size: 48px;
  font-weight: 500;
  line-height: 58.09px;
  color: rgba(15, 85, 123, 1);
}

</style>