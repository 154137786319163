<template>
    <div>
        <section class="banner d-flex align-items-center justify-content-center cleartx">
            <div class="container-fluid">
                <img src="../assets/img/Media/mediaSection.png" class="img" alt="csr">
                <!-- <div class="container-fluid">
              <div class="row justify-content-center align-items-center">
                  <div class="col-md-8 text-center">
                      <img src="./img/About/homesectionbg.png" class="img-fluid position-relative" alt="csr">
                      <h2 class="aboutbannertitle">Media</h2>
                  </div>
              </div>
          </div> -->
            </div>
        </section>

        <section class="cleartx">
            <div class="container">
                <div class="row">
                    <div class="col-sm-12 mt-5">
                        <p style=" font-size: 16px; font-weight: 500; line-height: 19.36px; text-align: left; ">
                            / NEWS & MEDIA <span class="green-dot"></span></p>
                        <h2 class="mediaTitle">
                            Our Latest Activities
                        </h2>
                    </div>
                </div>
            </div>
            <!-- ----------------------- -->
            <div class="container mt-3">
                <div class="row">
                    <div class="col-sm-6 ">
                        <div class="card border-0 mt-5">
                            <div class="elfsight-app-d5e374c3-386b-47d7-a0c4-67b1346965e7" data-elfsight-app-lazy></div>
                            <div class="card-body border-0 mt-4">
                                <h5 class="card-title mt-3">Linkedin</h5>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6" >
                        
                        <div class="card border-0"  @click="Newsroom()">
                            <img src="../assets/img/Media/newsroom6.png" class="card-img-top" alt="Media topic">
                            <div class="card-body border-0">
                                <button type="button" class="btn btn-outline-dark rounded-pill custom-badge-load">25th
                                    October, 2024</button>
                                <h5 class="card-title mt-3">Newsroom</h5>
                            </div>
                        </div>
                        <div class="card border-0 " @click="blogs()">
                            <img src="../assets/img/Media/MediaBan3.png" class="card-img-top" alt="Media topic">
                            <div class="card-body border-0">

                                <button type="button" class="btn btn-outline-dark rounded-pill custom-badge-load">25th
                                    October, 2024</button>

                                <h5 class="card-title mt-3">Blogs</h5>
                            </div>
                        </div>
                       
                    </div>
                </div>
            </div>
        </section>

        <section class="cleartx">
            <div class="custom-line"></div>
            <div class="container mt-5">
                <div class="row mb-4">
                    <div class="col-md-6">
                        <h2 class="mediaTitle">Social media</h2>
                    </div>
                    <div class="col-md-6 d-flex justify-content-end align-items-center">
                        <router-link class="btn btn-outline-dark rounded-pill custom-badge-load px-5 py-2"
                            to="/social-media-detail">View All</router-link>
                    </div>
                </div>

                <div class="row">
                    <!-- Card 1 -->
                    <div class="col-md-4">
                        <div class="linkedin-post-embed">
                            <div class="elfsight-app-d5e374c3-386b-47d7-a0c4-67b1346965e7" data-elfsight-app-lazy></div>
                        </div>
                    </div>
                    <!-- Card 2 -->
                    <div class="col-md-4">
                        <div class="linkedin-post-embed">
                            <div class="elfsight-app-1d47e0aa-a050-4f29-bba6-b4fdeada865c" data-elfsight-app-lazy></div>
                        </div>
                    </div>
                    <!-- Card 3 -->
                    <div class="col-md-4">
                        <div class="linkedin-post-embed">
                            <div class="elfsight-app-33561550-38cb-432d-b26d-0bcbcf268b86" data-elfsight-app-lazy></div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="container mt-5">
                <div class="row mb-4">
                    <div class="col-md-6">
                        <h2 class="mediaTitle">Newsroom</h2>
                    </div>
                    <div class="col-md-6 d-flex justify-content-end align-items-center">
                        <router-link class="btn btn-outline-dark rounded-pill custom-badge-load px-5 py-2"
                            to="/Newsroom">View All</router-link>

                    </div>
                </div>
                <div class="row">
                    <!-- Card 1 -->
                    <div class="col-md-4">
                        <div class="card border-0">
                            <img src="../assets/img/Media/newsroom1.png" class="card-img-top" alt="Media topic">
                            <div class="card-body border-0">
                                <button type="button"
                                    class="btn btn-outline-dark rounded-pill custom-badge-load px-4">News</button>
                                <button type="button" class="btn btn-outline-dark rounded-pill custom-badge-load">25th October,2024</button>
                                <h5 class="card-title mt-3">BW Business World</h5>
                                <p>PIP’s Development Offers Prosperous Future For
                                    Chemical And Petrochemic...</p>
                            </div>
                        </div>
                    </div>
                    <!-- Card 2 -->
                    <div class="col-md-4">
                        <div class="card border-0">
                            <img src="../assets/img/Media/newsroom2.png" class="card-img-top" alt="Media topic">
                            <div class="card-body border-0">
                                <button type="button"
                                    class="btn btn-outline-dark rounded-pill custom-badge-load px-4">News</button>
                                <button type="button" class="btn btn-outline-dark rounded-pill custom-badge-load">25th October,2024</button>
                                <h5 class="card-title mt-3">Free Press Journal</h5>
                                <p>“PIP Aspires To Be Asia’s Ultimate Destination To Set
                                    Up Industry” – Siraj...</p>
                            </div>
                        </div>
                    </div>
                    <!-- Card 3 -->
                    <div class="col-md-4">
                        <div class="card border-0">
                            <img src="../assets/img/Media/newsroom3.png" class="card-img-top" alt="Media topic">
                            <div class="card-body border-0">
                                <button type="button"
                                    class="btn btn-outline-dark rounded-pill custom-badge-load px-4">News</button>
                                <button type="button" class="btn btn-outline-dark rounded-pill custom-badge-load">25th October,2024</button>
                                <h5 class="card-title mt-3">Construction Week
                                    Online</h5>
                                <p>The surprising role of industrial parks in India’s
                                    economic expansion...</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="cleartx">
            <div class="container">
                <div class="row mb-4">
                    <div class="col-md-6">
                        <h2 class="mediaTitle">Print media</h2>
                    </div>
                    <div class="col-md-6 d-flex justify-content-end align-items-center">
                    </div>
                </div>
                <div class="row">
                    <!-- Card 1 -->
                    <div class="col-md-4 d-flex align-items-stretch">
                        <div class="print-media-flip">
                            <div class="print-media-card">
                                <img src="../assets/img/Media/Aviation.png" class="card-img-top front"
                                    alt="Aviation Times">
                            </div>
                        </div>
                    </div>
                    <!-- Card 2 -->
                    <div class="col-md-4 d-flex align-items-stretch">
                        <div class="print-media-flip">
                            <div class="print-media-card">
                                <img src="../assets/img/Media/The_Morning_Standard.png" class="card-img-top front"
                                    alt="The Morning Standard">
                             
                            </div>
                        </div>
                    </div>
                    <!-- Card 3 -->
                    <div class="col-md-4 d-flex align-items-stretch">
                        <div class="print-media-flip">
                            <div class="print-media-card">
                                <img src="../assets/img/Media/Sunday_Standard.png" class="card-img-top front"
                                    alt="The Sunday Standard">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>


        <section class="cleartx bganimationcsr">
            <div class="container-fluid ">

                <form>
                    <div class="row" data-aos="fade-up">
                    
                    <div class="col-sm-6 d-flex justify-content-center p-2">
                        <img src="../assets/img/About/Vector.webp" alt="img-fluid" srcset="">
                    </div>
                    
                        <div class="col-sm-5 p-2">
                            <h2 class="mediaTitle">Media Kit</h2><br>
                            <form  @submit.prevent="checkSubmit" method="post">

                            <div class="mb-3 row">
                                <div class="col-sm-6 align-self-center  ">
                                    <label for="identitySelect" class="form-label">I would like to request for
                                        identities of:</label>
                                </div>
                                <div class="col-sm-5">
                                    <select class="form-control" id="identity" v-model="selectedIdentity">
                                        <option value="">Select Logo</option>
                                        <option value="AreteLogo">Arete Logo</option>
                                    </select>
                                </div>
                            </div>
                            <div class="row mt-5 m-0">
                                <div class="form-check col-sm-2">
                                    <input class="form-check-input" type="checkbox" value="png" id="png" v-model="selectedFormats">
                                    <label class="form-check-label" for="png">
                                        Png
                                    </label>
                                </div>
                                <div class="form-check col-sm-2">
                                    <input class="form-check-input" type="checkbox" value="jpg" id="Jpeg" v-model="selectedFormats">
                                    <label class="form-check-label" for="jpg">
                                        Jpeg
                                    </label>
                                </div>
                                <div class="form-check col-sm-3">
                                    <input class="form-check-input" type="checkbox" value="allformate" id="allformate"
                                        @change="selectAllFormats">
                                    <label class="form-check-label" for="allformate">
                                        all the formats
                                    </label>
                                </div>
                            </div>
                            <div class="mb-3">
                                <label for="staticEmail" class="col-sm-2 col-form-label">Email</label>
                                <div class="col-sm-10">
                                    <input type="email" class="form-control border-0 border-bottom inputbord"
                                        id="staticEmail" v-model="email" placeholder="brucewayne@domain.com" @blur="validateEmail" >
                                        <small v-if="emailError" class="text-danger">{{ emailError }}</small>
                                </div>
                            </div>
                            <div class="mb-3 mt-5 m-0">
                                <button type="button"
                                    class="btn btn-outline-dark rounded-pill custom-badge-load px-5 py-2" @click="downloadFiles">Download</button>
                            </div>
                            </form>
                            
                        </div>
                    </div>
                    
                </form>
            </div>
        </section>

    </div>
</template>

<script>
import { mapActions } from "vuex"

export default {
    data() {
    return {
      selectedIdentity: 'AreteLogo', // Default selected identity
      selectedFormats: [], // Store selected formats (checkbox values)
      email: '',
      emailError: null,
      files: {
        png: require('@/assets/img/logo.png'),
        jpg: require('@/assets/img/logo.jpg')
        }
    };
  },
  computed: {
    ...mapActions(['submitdata']),
      },
    mounted() {
        // Dynamically add the Elfsight platform script
        const script = document.createElement("script");
        script.src = "https://static.elfsight.com/platform/platform.js";
        script.async = true;
        document.body.appendChild(script);
    },
    methods: {
        validateEmail() {
            const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
            if (!this.email) {
                this.emailError = 'Email is required';
            } else if (!emailPattern.test(this.email)) {
                this.emailError = 'Please enter a valid email address';
            } else {
                this.emailError = null;
            }
        },
        selectAllFormats(event) {
      if (event.target.checked) {
        this.selectedFormats = ['png', 'jpg'];
      } else {
        this.selectedFormats = [];
      }
    },
        // Trigger file download based on selected formats
    downloadFiles() {
      if (this.email === '') {
        alert("Please enter a valid email.");
        return;
      }
      
      let formatsToDownload = this.selectedFormats;
 
      if (formatsToDownload.length === 0) {
        alert("Please select at least one format to download.");
        return;
      }

      if(this.emailError)
      {
        alert("Please enter a valid email.");
        return;
      }
 
      this.checkSubmit()
      formatsToDownload.forEach(format => {
        const link = document.createElement('a');
        link.href = this.files[format]; // File name to download
        link.download = this.files[format]; // File name on download
        link.download = `Aretelogo.${format === 'jpeg' ? 'jpg' : format}`;
        link.click(); // Trigger the download
      });
 
      //alert(`Download initiated for ${formatsToDownload.join(", ")} formats.`);
    },
        blogs() {
            this.$router.push({ name: 'Mainblogs' });
        },
        Newsroom(){
            this.$router.push({ name: 'Newsroom' });
        },
        async checkSubmit()
        { 
            let payload={
                email:this.email,
            }

            console.log('payload',payload);

                await this.$store.dispatch('submitMediaKit',payload);

                    this.$swal({
                            text: 'Thank you for contacting us! Your message has been successfully received.',
                            target: '#custom-target',
                            timer: 2000,
                            toast: true,
                            position: 'top'
                        });

                        this.clearForm();
                    // setTimeout(() => {
                    //   //this.$router.push('/contactus');
                    // }, 1000);
            
        
        },
        clearForm() {
        this.name="";
            this.email="";
        }

        }
};
</script>


<style scroped>
.responsive {
    max-width: 100%;
    height: auto;
}


.print-media-flip {
    perspective: 1000px;
    /* Give depth for the 3D effect */
    width: 100%;
    /* Full width */
    height: 300px;
    /* Fixed height for cards */
}

.print-media-card {
    position: relative;
    width: 100%;
    height: 100%;
    /* Full height */
    transition: transform 0.6s;
    transform-style: preserve-3d;
}



.eapps-widget-toolbar-panel {
    display: none;
}

@media (max-width: 600px) {
    .card {
        height: 50%;
    }
}

</style>