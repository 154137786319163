import { createApp } from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import 'vue3-carousel/dist/carousel.css'
import { createHead } from '@vueuse/head';

import { Swiper, SwiperSlide } from 'swiper/vue';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-cube';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Autoplay, Pagination, Navigation,EffectCube } from 'swiper/modules';



// Initialize Vue app
const app = createApp(App);
const head = createHead();

// Use the router, store, and plugins
app.use(router);
app.use(store);
app.use(VueSweetalert2);
app.use(SwiperSlide);
app.use(head);
app.use(Swiper, {
  modules: [Autoplay, Pagination, Navigation,EffectCube]
});
// Mount the app and initialize AOS after mounting
app.mount('#app');

// Initialize AOS after the app is mounted
AOS.init({
  // Add your AOS options here
  once: false, // Animation happens only once
  duration: 1000, // Set the animation duration
  delay: 200, // Optional delay
});
