
<template>

  <div>

    <div id="loading-wrapper" v-if="loader">
        <div id="loading-text"></div>
        <div id="loading-content">
        <img src="../assets/img/loader.gif" alt="Loading" id="preloader-image">
        </div>
    </div>
     <!-- Banner Section -->
     <section class="banner d-flex align-items-center justify-content-center position-relative cleartx" style="background: #fdfefd;">
    <div class="video-container">
        <video id="introVideo" ref="introVideo"  muted loop playsinline @error="handleVideoError" @canplaythrough="handleVideoLoaded" v-if="videoSupported">
                <source src="../assets/video/Arete.ogv" type="video/ogg" media="(min-width: 1920px)">    
                <source src="../assets/video/aretebanner.mp4" type="video/mp4">
                <source src="../assets/video/banner.webm" type="video/webm">
                <source src="../assets/video/Arete.ogv" type="video/ogg">
                <source src="../assets/video/test.webm" type="video/webm">
                <!-- Lower resolution video for mobile devices -->
                Your browser does not support the video tag.
        </video>

        <video v-else id="introVideo" ref="introVideo"  muted loop playsinline>
            <source src="../assets/video/test.webm" type="video/webm">
            Your browser does not support the video tag.
        </video>

        <!-- Fallback image if video is not supported -->
        <!-- <img v-else :src="fallbackImage" alt="Fallback Image" class="fallback-image img-fluid"> -->

    </div>
    
    <div class="ellipse-arrow11 position-absolute bottom-0 end-0 mb-3 me-3" @click="toggleMute">
        <div class="ellipse-arrow-gi">
            <img :src="srcmuted" class="img-fluid" alt="aretegroup" style="width: 40px;height: 40px;">
        </div>
    </div>

    </section>




    <section class="cleartx valuesSection videomutesec">
        <div class="container">
            <div class="card-group card-mobile-home">
                <div class="card border-0 transition-fade" data-aos="fade-up">
                    <div  class="card-body count-up">
                        <h5 class="card-title home-second-section-title counter-count">34 </h5><span class="card-title home-second-section-title"> +</span>
                        <p class="card-text  mt-2 media-second-column-title">years of journey</p>
                    </div>
                </div>
                <div class="slant-line-index"></div>
                <!-- <div class="card border-0 transition-fade" data-aos="fade-up">
                    <div class="card-body count-up">
                        <h5 class="card-title home-second-section-title counter-count">5 </h5><span class="card-title home-second-section-title"> +</span>
                        <p class="card-text  mt-2 media-second-column-title">million sq. ft. of
                            commercial <br>and
                            residential land bank</p>
                    </div>
                </div>
                <div class="slant-line-index"></div> -->
                <div class="straight-line-index" id="straight-line"></div>
                <div class="card border-0 transition-fade" data-aos="fade-up">
                    <div class="card-body count-up" >
                        <h5 class="card-title home-second-section-title counter-count">7000 </h5><span class="card-title home-second-section-title"> +</span>
                        <p class="card-text   mt-2 media-second-column-title">acres of Industrial Parks</p>
                    </div>
                </div>
                <div class="slant-line-index"></div>
                <div class="card border-0 transition-fade" data-aos="fade-up">
                    <div class="card-body count-up" >
                        <h5 class="card-title home-second-section-title counter-count">10 </h5><span class="card-title home-second-section-title"> +</span>
                        <p class="card-text  mt-2 media-second-column-title">Owned brands</p>
                    </div>
                </div>
            </div>
        </div>
    </section>

   <section class="cleartx homesectionbg valuesSection videomutesec" data-swup-scroll-container >
    <div class="container">
        <div class="row">
          <div class="col-md-6" >
            <div class="media-coverage-values" data-aos="fade-up">
                <h3 class="text-uppercase title indexheader breadcrumbs maintitle">/  Values create value<span class="green-dot"></span> </h3>
                <br>
                <div class="text-container mb-sm-0 mb-4" data-aos="fade-up">
                    <h1 class="value-Create-title">A Journey Powered <br /> By Values</h1>
                </div>
            </div>
          </div>
          
          <div class="col-md-6 " data-aos="fade-up">

                <div class="media-second-column ">
                    <p class="a-journey-powered  media-second-column-title mb-4">Established in 1990, The Arete Group’s journey has been
                        characterised by resilience, sustained growth and purposeful expansion across multifarious
                        sectors. Each of our endeavours underscores our unwavering commitment to long-term strategic
                        foresight and driving impactful outcomes.</p>
                    <p class="a-journey-powered media-second-column-title">We are of the firm belief that to achieve lasting success, it has
                        to be anchored in a bedrock of unwavering values. Because when values lead the way, value
                        naturally follows. Our relentless pursuit of value creation extends beyond self-interest,
                        encompassing the holistic well-being of all our stakeholders.</p>
                </div>
                <!-- <span class="icon icon-arrow roundrrrow mt-3"></span> -->
                <router-link to="/about">
                    <div class="ellipse-arrow" @mouseenter="showArrow"  @mouseleave="hideArrow">
                        <div class="arrow-outward" to="/about"></div>
                    </div>
                </router-link>


          </div>
        </div>
      </div> 
   </section>

   <section class="cleartx videomutesec">
        <div class="container">
            <div class="row">
                <div class="col-md-12 mb-5" >
                    <div class="media-coverage" >
                        <h3 class="text-uppercase breadcrumbs maintitle">/  Business Verticals<span class="green-dot"></span> </h3>
                        <br>
                        <h2 class="Businesstitle">Demonstrated Excellence Across Diverse Sectors</h2>
                    </div>
                </div>
                <div class="col-sm-12">
                    <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                        <li class="nav-item" role="presentation">
                            <!-- <button class="nav-link active" @click="handleClick('pillsHome')" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">Industrial Parks</button> -->
                            <button 
                                :class="{'nav-link': true, 'active': activeTab === 'pillsHome'}" 
                                @click="setActiveTab('pillsHome')" 
                                id="pills-home-tab" 
                                data-bs-toggle="pill" 
                                data-bs-target="#pills-home" 
                                type="button" 
                                role="tab" 
                                aria-controls="pills-home" 
                                :aria-selected="activeTab === 'pillsHome'">
                                Industrial Parks
                            </button>
                        </li>
                        <li class="nav-item" role="presentation">
                            <!-- <button class="nav-link" @click="handleClick('pillsProfile')" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">Real Estate</button> -->
                            <button 
                                :class="{'nav-link': true, 'active': activeTab === 'pillsProfile'}" 
                                @click="setActiveTab('pillsProfile')" 
                                id="pills-profile-tab" 
                                data-bs-toggle="pill" 
                                data-bs-target="#pills-profile" 
                                type="button" 
                                role="tab" 
                                aria-controls="pills-profile" 
                                :aria-selected="activeTab === 'pillsProfile'">
                                Real Estate
                            </button>
                        </li>
                        <li class="nav-item" role="presentation">
                            <!-- <button class="nav-link" @click="handleClick('pillsContact')" id="pills-contact-tab" data-bs-toggle="pill" data-bs-target="#pills-contact" type="button" role="tab" aria-controls="pills-contact" aria-selected="false">Hospitality</button> -->
                            <button 
                                :class="{'nav-link': true, 'active': activeTab === 'pillsContact'}" 
                                @click="setActiveTab('pillsContact')" 
                                id="pills-contact-tab" 
                                data-bs-toggle="pill" 
                                data-bs-target="#pills-contact" 
                                type="button" 
                                role="tab" 
                                aria-controls="pills-contact" 
                                :aria-selected="activeTab === 'pillsContact'">
                                Hospitality
                            </button>
                        </li>
                        <li class="nav-item" role="presentation">
                            <!-- <button class="nav-link" @click="handleClick('pillsRetail')" id="pills-Retail-tab" data-bs-toggle="pill" data-bs-target="#pills-Retail" type="button" role="tab" aria-controls="pills-Retail" aria-selected="false">Retail</button> -->
                            <button 
                                :class="{'nav-link': true, 'active': activeTab === 'pillsRetail'}" 
                                @click="setActiveTab('pillsRetail')" 
                                id="pills-Retail-tab" 
                                data-bs-toggle="pill" 
                                data-bs-target="#pills-Retail" 
                                type="button" 
                                role="tab" 
                                aria-controls="pills-Retail" 
                                :aria-selected="activeTab === 'pillsRetail'">
                                Retail
                            </button>
                        </li>
                        <li class="nav-item" role="presentation">
                            <!-- <button class="nav-link" @click="handleClick('pillsInvestments')" id="pills-Investments-tab" data-bs-toggle="pill" data-bs-target="#pills-Investments" type="button" role="tab" aria-controls="pills-Investments" aria-selected="false">Investments</button> -->
                            <button 
                                :class="{'nav-link': true, 'active': activeTab === 'pillsInvestments'}" 
                                @click="setActiveTab('pillsInvestments')" 
                                id="pills-Investments-tab" 
                                data-bs-toggle="pill" 
                                data-bs-target="#pills-Investments" 
                                type="button" 
                                role="tab" 
                                aria-controls="pills-Investments" 
                                :aria-selected="activeTab === 'pillsInvestments'">
                                Investments
                            </button>
                        </li>
                        </ul>
                        <div class="tab-content" id="pills-tabContent">

                            <div class="tab-pane fade" :class="{ 'show active': activeTab === 'pillsHome' }" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                                <div class="row flex-reverse-mobile">
                                    <div class="col-lg-6 col-md-8 col-6 d-sm-block d-sm-none d-md-block p-sm-0  mb-2">
                                        <div class="ratio ">
                                            <video class="w-100 bussinessHeight" autoplay muted loop playsinline>
                                                <source src="../assets/video/industialpark.webm" type="video/webm">
                                                Your browser does not support the video tag.
                                            </video>
                                        </div>
                                    </div>
                                    
                                    <div class="col-lg-2  col-md-4  col-6  mb-2 mobilediv">
                                        <div class="col-sm-12 p-0 home-industrial-tablet">
                                            <div class="card mb-2 card89 p-0">
                                              <div class="card-body">
                                                <h5 class="card-title card89title">+15</h5>
                                                <p class="card-text"></p>
                                                <img class="a-1-icon" alt="aretegroup" src="../assets/icon/89arrow.png">
                                              </div>
                                            </div>
                                          </div>
                                          <div class="col-sm-12 home-industrial-tablet">
                                            <div class="card newscovg">
                                              <div class="card-body">
                                                <h5 class="card-title newscovgtitle">News Coverage</h5>
                                                <img class="a-1-icon1" alt="aretegroup" src="../assets/icon/newscourvg.png">
                                              </div>
                                            </div>
                                          </div>
                                    </div>
                                    <!-- <div class="col-sm-3">
                                        <img src="./img/indus-1.png" class="img w-100 bussinessHeight" alt="Industial-park">
                                    </div> -->
                                    <div class="col-lg-4 col-md-12 businessBg  mb-2 home-description-tablet">
                                        <div class="media-thrid-column p-1 my-4 ">
                                            <p class="a-journey-powered">Our industrial parks cater to a diverse range of sectors, including chemicals, petrochemicals, textiles, and polymers, providing them with suitable locations for setting up manufacturing plants.</p>
                                                <p class="a-journey-powered">Our offering includes advanced effluent treatment facilities on site, and support in obtaining regulatory approvals and environmental clearances.</p>
                                                <router-link to="/industrial-parks">
                                                <a class="py-4 px-4 icon2 icon-arrow1 roundrrrow"></a>
                                                </router-link>

                                        
                                               
                                               <div class="">     <div class=" text-center">
                                                    <h3 class="text-uppercase title indexheader  maintitle-center"> Partners
                                                        <!-- <span class="green-dot"></span> -->
                                                    </h3>
                                                        <br>
                                                    </div>
                                                    <swiper :slidesPerView="2" 
                                                    :spaceBetween="0"
                                                    :centeredSlides="true"
                                                    :autoplay="{
                                                        delay: 2500,
                                                        disableOnInteraction: false,
                                                    }"
                                                    :pagination="false"
                                                    :navigation="false"
                                                    :modules="modules"
                                                    class="mySwiper"
                                                    loop="true"
                                                    :breakpoints="{
                                                    640: {
                                                        slidesPerView: 2,
                                                    },
                                                    768: {
                                                        slidesPerView: 3,
                                                    },
                                                    1024: {
                                                        slidesPerView: 3,
                                                    }
                                                    }"
                                                    >
                                                    <swiper-slide><div class="unique-slide"><div class="unique-card"><img src="../assets/img/Home/Partners/5.png" alt="The Economic Times" /></div></div></swiper-slide>
                                                    <swiper-slide><div class="unique-slide"><div class="unique-card"><img src="../assets/img/Home/Partners/10.png" alt="The Economic Times" /></div></div></swiper-slide>
                                                    <swiper-slide><div class="unique-slide"><div class="unique-card"><img src="../assets/img/Home/Partners/28.webp" alt="The Economic Times" /></div></div></swiper-slide>
                                                    <swiper-slide><div class="unique-slide"><div class="unique-card"><img src="../assets/img/Home/Partners/29.webp" alt="The Economic Times" /></div></div></swiper-slide>
                                                    
                                                    <swiper-slide><div class="unique-slide"><div class="unique-card"><img src="../assets/img/Home/Partners/30.png" alt="The Economic Times" /></div></div></swiper-slide>
                                                    <swiper-slide><div class="unique-slide"><div class="unique-card"><img src="../assets/img/Home/Partners/31.png" alt="The Economic Times" /></div></div></swiper-slide>
                                                    <swiper-slide><div class="unique-slide"><div class="unique-card"><img src="../assets/img/Home/Partners/32.png" alt="The Economic Times" /></div></div></swiper-slide>
                                                    <swiper-slide><div class="unique-slide"><div class="unique-card"><img src="../assets/img/Home/Partners/33.png" alt="The Economic Times" /></div></div></swiper-slide>
                                                    <swiper-slide><div class="unique-slide"><div class="unique-card"><img src="../assets/img/Home/Partners/34.png" alt="The Economic Times" /></div></div></swiper-slide>
                                                    <swiper-slide><div class="unique-slide"><div class="unique-card"><img src="../assets/img/Home/Partners/35.png" alt="The Economic Times" /></div></div></swiper-slide>
                                                    <swiper-slide><div class="unique-slide"><div class="unique-card"><img src="../assets/img/Home/Partners/36.png" alt="The Economic Times" /></div></div></swiper-slide>
                                                
                                                    </swiper>
                                                </div>
                                                </div>
                                                <!-- <img class="a-1-icon2" alt="aretegroup" src="./icon/bussinessLine3.png"> -->
                                    </div>
                                </div>
                            </div>
                            <!-- second starting  -->
                            <div class="tab-pane fade" :class="{ 'show active': activeTab === 'pillsProfile' }" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                                <div class="row flex-reverse-mobile">
                                    <div class="col-lg-6 col-md-8 col-6 d-sm-block d-sm-none d-md-block p-sm-0  mb-2">
                                        <div class="ratio ">
                                            <video class="w-100 bussinessHeight" autoplay muted loop playsinline>
                                                <source src="../assets/video/real.webm" type="video/webm">
                                                Your browser does not support the video tag.
                                            </video>
                                        </div>
                                    </div>
                                    
                                    <div class="col-lg-2  col-md-4  col-6  mb-2 mobilediv">
                                        <div class="col-sm-12 p-0 home-industrial-tablet">
                                            <div class="card mb-2 card89 p-0">
                                              <div class="card-body">
                                                <h5 class="card-title card89title">+10</h5>
                                                <p class="card-text"></p>
                                                <img class="a-1-icon" alt="aretegroup" src="../assets/icon/89arrow.png">
                                              </div>
                                            </div>
                                          </div>
                                          <div class="col-sm-12 home-industrial-tablet">
                                            <div class="card newscovg">
                                              <div class="card-body">
                                                <h5 class="card-title newscovgtitle">News Coverage</h5>
                                                <img class="a-1-icon1" alt="aretegroup" src="../assets/icon/newscourvg.png">
                                              </div>
                                            </div>
                                          </div>
                                    </div>
                                    <!-- <div class="col-sm-3">
                                        <img src="./img/indus-1.png" class="img w-100 bussinessHeight" alt="Industial-park">
                                    </div> -->
                                    <div class="col-lg-4 col-md-12 businessBg  mb-2 home-description-tablet">
                                        <div class="media-thrid-column p-1 my-4">
                                                <p class="a-journey-powered">Arete real estate spearheads the development of premium commercial and residential projects that are designed to enhance communities.</p>
                                                <router-link to="/RealEstate">
                                                    <a  class="py-4 px-4 icon2 icon-arrow1 roundrrrow"></a>
                                                </router-link>

                                        
                                               
                                </div>
                                                <!-- <img class="a-1-icon2" alt="aretegroup" src="./icon/bussinessLine3.png"> -->
                                    </div>
                                </div>
                            </div> 
                            <!-- thrid section -->
                            <div class="tab-pane fade" :class="{ 'show active': activeTab === 'pillsContact' }" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab">
                                <div class="row flex-reverse-mobile">
                                    <div class="col-lg-7 col-md-8 col-6 d-sm-block d-sm-none d-md-block p-sm-0  mb-2">
                                        <div class="ratio ratio-16x9">
                                            <video class="w-100 bussinessHeight" autoplay muted loop playsinline>
                                                <source src="../assets/video/demo.webm" type="video/webm">
                                                Your browser does not support the video tag.
                                            </video>
                                        </div>
                                    </div>    
                                    <div class="col-lg-2 col-md-4 col-sm-2  col-6  mb-2 mobilediv" >
                                        <div class="col-sm-12 p-0 home-industrial-tablet" >
                                            <div class="card mb-2 card89 p-0">
                                              <div class="card-body count-up mt-5">
                                                <span class="card-title card89title ">+ </span><h5 class="card-title card89title counter-count">5</h5>
                                                <p class="card-text"></p>
                                                <img class="a-1-icon" alt="aretegroup" src="../assets/icon/89arrow.png">
                                              </div>
                                            </div>
                                          </div>
                                          <div class="col-sm-12 home-industrial-tablet" >
                                            <div class="card newscovg">
                                              <div class="card-body">
                                                <h5 class="card-title newscovgtitle">News Coverage</h5>
                                                <img class="a-1-icon1" alt="aretegroup" src="../assets/icon/newscourvg.png">
                                              </div>
                                            </div>
                                          </div>
                                    </div>
                                    <!-- <div class="col-sm-3">
                                        <img src="./img/indus-1.png" class="img w-100 bussinessHeight" alt="Industial-park">
                                    </div> -->
                                    <div class="col-lg-3 col-md-12 businessBg  mb-2 home-description-tablet">
                                        <div class="media-thrid-column p-1 my-4">
                                            <p class="a-journey-powered">Arete hospitality has cultivated a reputation for crafting top-notch stay and dining experiences. Whether it is at a piush hotel catering to discerning corporate travellers, or at one of our boutique restaurants that dish out delightfully authentic fare, the devotion to excellence is all too evident.</p>
                                            <router-link to="/Hospitality">
                                                <a  class="py-4 px-4 icon2 icon-arrow1 roundrrrow"></a>
                                            </router-link>
                                            <div class="">     <div class=" text-center">
                                                    <h3 class="text-uppercase title indexheader  maintitle-center"> Partners
                                                        <!-- <span class="green-dot"></span> -->
                                                    </h3>
                                                        <br>
                                                    </div>
                                                    <swiper :slidesPerView="2" 
                                                    :spaceBetween="0"
                                                    :centeredSlides="true"
                                                    :autoplay="{
                                                        delay: 2500,
                                                        disableOnInteraction: false,
                                                    }"
                                                    :pagination="false"
                                                    :navigation="false"
                                                    :modules="modules"
                                                    class="mySwiper"
                                                    loop="true"
                                                    :breakpoints="{
                                                    640: {
                                                        slidesPerView: 2,
                                                    },
                                                    768: {
                                                        slidesPerView: 3,
                                                    },
                                                    1024: {
                                                        slidesPerView: 2,
                                                    }
                                                    }"
                                                    >
                                                    <swiper-slide><div class="unique-slide"><div class="unique-card"><img src="../assets/img/Home/MediaCoverage/11.png" alt="The Economic Times" /></div></div></swiper-slide>
                                                   
                                                    </swiper>
                                                </div>
                                                    
                                        </div>
                                                <!-- <img class="a-1-icon2" alt="" src="./icon/bussinessLine3.png"> -->
                                    </div>
                                </div>
                            </div>
                            <!-- retail starting -->
                            <div  class="tab-pane fade" :class="{ 'show active': activeTab === 'pillsRetail' }" id="pills-Retail" role="tabpanel"  aria-labelledby="pills-Retail-tab">
                                <div class="row flex-reverse-mobile">
                                    <div class="col-lg-6 col-md-8 col-6 d-sm-block d-sm-none d-md-block p-sm-0  mb-2">
                                        <div class="ratio ">
                                            <video class="w-100 bussinessHeight" autoplay muted loop playsinline>
                                                <source src="../assets/video/retail.webm" type="video/webm">
                                                Your browser does not support the video tag.
                                            </video>
                                        </div>
                                    </div>
                                    
                                    <div class="col-lg-2 col-md-4  col-6  mb-2 mobilediv">
                                        <div class="col-sm-12 p-0 home-industrial-tablet">
                                            <div class="card mb-2 card89 p-0">
                                              <div class="card-body">
                                                <h5 class="card-title card89title">+ 10</h5>
                                                <p class="card-text"></p>
                                                <img class="a-1-icon" alt="" src="../assets/icon/89arrow.png">
                                              </div>
                                            </div>
                                          </div>
                                          <div class="col-sm-12 home-industrial-tablet">
                                            <div class="card newscovg">
                                              <div class="card-body">
                                                <h5 class="card-title newscovgtitle">News Coverage</h5>
                                                <img class="a-1-icon1" alt="" src="../assets/icon/newscourvg.png">
                                              </div>
                                            </div>
                                          </div>
                                    </div>
                                    <!-- <div class="col-sm-3">
                                        <img src="./img/indus-1.png" class="img w-100 bussinessHeight" alt="Industial-park">
                                    </div> -->
                                    <div class="col-lg-4 col-md-12 businessBg  mb-2 home-description-tablet">
                                        <div class="media-thrid-column p-1 my-4">
                                            <p class="a-journey-powered">At Arete retail we focus on crafting elevated shopping experiences by bringing together a carefully curated mix of brands tailored to diverse customer segments. Our commitment to our franchise partners goes beyond provision of space alone, and also spans achievement of higher footfalls, improved inventory turnover, and increased profitability.</p>                                               
                                            <router-link to="/retail">
                                                <a class="py-4 px-4 icon2 icon-arrow1 roundrrrow"></a>
                                            </router-link>
                                            <div class="">     <div class=" text-center">
                                                    <h3 class="text-uppercase title indexheader  maintitle-center"> Partners
                                                        <!-- <span class="green-dot"></span> -->
                                                    </h3>
                                                        <br>
                                                    </div>
                                                    <swiper :slidesPerView="2" 
                                                    :spaceBetween="0"
                                                    :centeredSlides="true"
                                                    :autoplay="{
                                                        delay: 2500,
                                                        disableOnInteraction: false,
                                                    }"
                                                    :pagination="false"
                                                    :navigation="false"
                                                    :modules="modules"
                                                    class="mySwiper"
                                                    loop="true"
                                                    :breakpoints="{
                                                    640: {
                                                        slidesPerView: 2,
                                                    },
                                                    768: {
                                                        slidesPerView: 3,
                                                    },
                                                    1024: {
                                                        slidesPerView: 3,
                                                    }
                                                    }"
                                                    >
                                                    <swiper-slide><div class="unique-slide"><div class="unique-card"><img src="../assets/img/Home/Partners/1.png" alt="The Economic Times" /></div></div></swiper-slide>
                                                    <swiper-slide><div class="unique-slide"><div class="unique-card"><img src="../assets/img/Home/Partners/2.png" alt="The Economic Times" /></div></div></swiper-slide>
                                                    <swiper-slide><div class="unique-slide"><div class="unique-card"><img src="../assets/img/Home/Partners/6.png" alt="The Economic Times" /></div></div></swiper-slide>
                                                    <swiper-slide><div class="unique-slide"><div class="unique-card"><img src="../assets/img/Home/Partners/7.png" alt="The Economic Times" /></div></div></swiper-slide>
                                                    <swiper-slide><div class="unique-slide"><div class="unique-card"><img src="../assets/img/Home/Partners/9.png" alt="The Economic Times" /></div></div></swiper-slide>
                                                    <swiper-slide><div class="unique-slide"><div class="unique-card"><img src="../assets/img/Home/Partners/101.png" alt="The Economic Times" /></div></div></swiper-slide>
                                                    <swiper-slide><div class="unique-slide"><div class="unique-card"><img src="../assets/img/Home/Partners/102.png" alt="The Economic Times" /></div></div></swiper-slide>
                                                    <swiper-slide><div class="unique-slide"><div class="unique-card"><img src="../assets/img/Home/Partners/103.png" alt="The Economic Times" /></div></div></swiper-slide>                                                   
                                                    </swiper>
                                                </div>

                                        </div>
                                                <!-- <img class="a-1-icon2" alt="" src="./icon/bussinessLine3.png"> -->
                                    </div>
                                </div>
                            </div>
                            <!-- investments starting -->
                            <div class="tab-pane fade" :class="{ 'show active': activeTab === 'pillsInvestments' }" id="pills-Investments" role="tabpanel" aria-labelledby="pills-Investments-tab">
                                <div class="row flex-reverse-mobile">
                                    <div class="col-sm-8 col-md-8 col-6 d-sm-block d-sm-none d-md-block p-sm-0 ">
                                        <div class="ratio ratio-16x9">
                                            <video class="w-100 bussinessHeight" autoplay muted loop playsinline>
                                                <source src="../assets/video/inverstment.webm" type="video/webm">
                                                Your browser does not support the video tag.
                                            </video>
                                        </div>
                                    </div>
                                    
                                    <div class="col-lg-2 col-md-4  col-6  mb-2 mobilediv">
                                        <div class="col-sm-12 p-0 home-industrial-tablet">
                                            <div class="card mb-2 card89 p-0">
                                              <div class="card-body">
                                                <h5 class="card-title card89title">+ 5</h5>
                                                <p class="card-text"></p>
                                                <img class="a-1-icon" alt="aretegroup" src="../assets/icon/89arrow.png">
                                              </div>
                                            </div>
                                          </div>
                                          <div class="col-sm-12 home-industrial-tablet">
                                            <div class="card newscovg">
                                              <div class="card-body">
                                                <h5 class="card-title newscovgtitle">News Coverage</h5>
                                                <img class="a-1-icon1" alt="aretegroup" src="../assets/icon/newscourvg.png">
                                              </div>
                                            </div>
                                          </div>
                                    </div>
                                    <div class="col-lg-2 col-md-12 businessBg  mb-2 home-description-tablet">
                                        <div class="media-thrid-column p-1 my-4">
                                            <p class="a-journey-powered">Arete investments identifies and invests in businesses with long-term potential. We foster enduring partnerships and provide strategic support to elevate our partner companies.</p>
                                            <router-link to="/investments">
                                                <a class="py-4 px-4 icon2 icon-arrow1 roundrrrow"></a>
                                            </router-link>
                                            <div class="">     <div class=" text-center">
                                                    <h3 class="text-uppercase title indexheader  maintitle-center"> Partners
                                                        <!-- <span class="green-dot"></span> -->
                                                    </h3>
                                                        <br>
                                                    </div>
                                                    <swiper :slidesPerView="2" 
                                                    :spaceBetween="0"
                                                    :centeredSlides="true"
                                                    :autoplay="{
                                                        delay: 2500,
                                                        disableOnInteraction: false,
                                                    }"
                                                    :pagination="false"
                                                    :navigation="false"
                                                    :modules="modules"
                                                    class="mySwiper"
                                                    :loop="true"
                                                    :breakpoints="{
                                                    640: {
                                                        slidesPerView: 2,
                                                    },
                                                    768: {
                                                        slidesPerView: 2,
                                                    },
                                                    1024: {
                                                        slidesPerView: 1,
                                                    }
                                                    }"
                                                    >
                                                    <swiper-slide><div class="unique-slide"><div class="unique-card"><img src="../assets/img/Home/Partners/3.png" alt="The Economic Times" /></div></div></swiper-slide>
                                                   
                                                    </swiper>
                                                </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                </div>
            </div>
        </div>
   </section>

    <section class="mb-4 mb-md-0">
        <div class="container">
            <div class="value-section">
                <div class="left-content col-sm-8"  data-aos="fade-up">
                    <h3 class="text-uppercase title indexheader breadcrumbs maintitle">/  OUR VALUES<span class="green-dot"></span> </h3>
                    <br>
                    <div class="title-section"  data-aos="fade-up">
                        <h2 class="main-title">Values <span class="subtitle"> are our cornerstone</span></h2>
                    </div>
                </div>
                <div class="right-content col-sm-4"  data-aos="fade-up">
                    <div class="slant-line-home d-none d-sm-block"></div>
                    <p class="description p-md-4 p-0">
                        Our core values are the cornerstone of our
                        organisational ethos, driving us towards sustained
                        excellence and growth.
                    </p>
                </div>
            </div>
            <!-- ------------------------card---------------------- -->
                <div class="row row-cols-1 row-cols-xs-2 row-cols-sm-2 row-cols-lg-4 g-3">
                    <div class="col">
                        <div class="card h-100 shadow-sm values-custom-card p-5">
                            <img class="card-img-top values-card-img-top" alt="aretegroup" src="../assets/svg/Values_1.svg" style="height:100px; width: 100px; padding-top:40px ;" />
                            <div class="card-body">
                                <h5 class="card-title vluesTitle">Excellence</h5>
                                <p class="card-text valueDescr">We set unprecedented benchmarks,
                                    continually pushing boundaries to achieve
                                    superior quality outcomes in all our endeavors.</p>
                            </div> 
                        </div>
                    </div>
                    <div class="col">
                        <div class="card h-100 shadow-sm values-custom-card-1 p-5">
                            <img class="card-img-top values-card-img-top" alt="aretegroup" src="../assets/svg/Values_2.svg" style="height:100px; width: 100px; padding-top:40px ;" />
                            <div class="card-body">
                                <h5 class="card-title vluesTitle">Empowerment</h5>
                                <p class="card-text valueDescr">We foster an environment of
                                    autonomy and accountability,enabling team members
                                    to realise their full potential and take iwnership
                                    of their actions.</p>
                            </div> 
                        </div>
                    </div>
                    <div class="col">
                        <div class="card h-100 shadow-sm values-custom-card-2 p-5">
                            <img class="card-img-top values-card-img-top" alt="aretegroup" src="../assets/svg/Values_3.svg" style="height:100px; width: 100px; padding-top:40px ;" />
                            <div class="card-body">
                                <h5 class="card-title vluesTitle">Empathy</h5>
                                <p class="card-text valueDescr">We culitivate understanding,support, and recognition to create an
                                    inclusive
                                    workspace and stakeholder ecosystem.</p>
                            </div> 
                        </div>
                    </div>
                    <div class="col">
                        <div class="card h-100 shadow-sm values-custom-card-3 p-5">
                            <img class="card-img-top values-card-img-top" alt="aretegroup" src="../assets/svg/Values_4.svg" style="height:100px; width: 100px; padding-top:40px ;" />
                            <div class="card-body">
                                <h5 class="card-title vluesTitle">Resilience</h5>
                                <p class="card-text valueDescr">We have embedded adaptibility,in
                                    our organisational DNA,equipping us to manage
                                    change,innovate, and creatively solve
                                    challenges.</p>
                            </div> 
                        </div>
                    </div>
                            
                </div>

        </div>
    </section>

    <section class="cleartx ">
        <div class="container">
            <div class="row">
                <div class="media-coverage" data-aos="fade-up">
                    <h3 class="text-uppercase title indexheader breadcrumbs maintitle">/   Media Coverage<span class="green-dot"></span> </h3>
                    <br>
                </div>

                <swiper :slidesPerView="2" 
                    :spaceBetween="40"
                    :centeredSlides="true"
                    :autoplay="{
                        delay: 2500,
                        disableOnInteraction: false,
                    }"
                    :pagination="false"
                    :navigation="false"
                    :modules="modules"
                    class="mySwiper"
                    loop="true"
                    :breakpoints="{
                    640: {
                        slidesPerView: 2,
                    },
                    768: {
                        slidesPerView: 3,
                    },
                    1024: {
                        slidesPerView: 6,
                    }
                    }"
                    >
                    <swiper-slide><div class="unique-slide"><div class="unique-card"><img src="../assets/img/Home/MediaCoverage/27.png" alt="The Economic Times" /></div></div></swiper-slide>
                    <swiper-slide><div class="unique-slide"><div class="unique-card"><img src="../assets/img/Home/MediaCoverage/11.png" alt="The Economic Times" /></div></div></swiper-slide>
                    <swiper-slide><div class="unique-slide"><div class="unique-card"><img src="../assets/img/Home/MediaCoverage/12.png" alt="The Economic Times" /></div></div></swiper-slide>
                    <swiper-slide><div class="unique-slide"><div class="unique-card"><img src="../assets/img/Home/MediaCoverage/13.png" alt="The Economic Times" /></div></div></swiper-slide>
                    <swiper-slide><div class="unique-slide"><div class="unique-card"><img src="../assets/img/Home/MediaCoverage/14.png" alt="The Economic Times" /></div></div></swiper-slide>
                    <swiper-slide><div class="unique-slide"><div class="unique-card"><img src="../assets/img/Home/MediaCoverage/15.png" alt="The Economic Times" /></div></div></swiper-slide>
                    <swiper-slide><div class="unique-slide"><div class="unique-card"><img src="../assets/img/Home/MediaCoverage/16.png" alt="The Economic Times" /></div></div></swiper-slide>
                    <swiper-slide><div class="unique-slide"><div class="unique-card"><img src="../assets/img/Home/MediaCoverage/17.png" alt="The Economic Times" /></div></div></swiper-slide>
                    <swiper-slide><div class="unique-slide"><div class="unique-card"><img src="../assets/img/Home/MediaCoverage/18.png" alt="The Economic Times" /></div></div></swiper-slide>
                    <swiper-slide><div class="unique-slide"><div class="unique-card"><img src="../assets/img/Home/MediaCoverage/19.png" alt="The Economic Times" /></div></div></swiper-slide>
                    <swiper-slide><div class="unique-slide"><div class="unique-card"><img src="../assets/img/Home/MediaCoverage/20.png" alt="The Economic Times" /></div></div></swiper-slide>
                    <swiper-slide><div class="unique-slide"><div class="unique-card"><img src="../assets/img/Home/MediaCoverage/21.png" alt="The Economic Times" /></div></div></swiper-slide>
                    <swiper-slide><div class="unique-slide"><div class="unique-card"><img src="../assets/img/Home/MediaCoverage/22.png" alt="The Economic Times" /></div></div></swiper-slide>

                </swiper>

            </div>
        </div>
    </section>

    <section class="cleartx casestudiesBg">
        <div class="container ">
            <div class="row text-center">
                <div class="col-lg-3 col-md-12 mb-5 d-flex align-items-center text-md-center">
                    <div class="left-content-1" data-aos="fade-up">
                        <h2 class="case mb-3">Case Studies</h2>
                        <router-link to="/case-studies">
                            <a class="view-all-1">View All</a>
                        </router-link>
                    </div>
                </div>
                <div class="col-lg-4 col-md-12 row" data-aos="fade-up">
                            
                                <swiper :slidesPerView="1" 
                                    :spaceBetween="40"
                                    :centeredSlides="true"
                                    :autoplay="{
                                        delay: 2500,
                                        disableOnInteraction: false,
                                    }"
                                    :pagination="false"
                                    :navigation="false"
                                    :modules="modules"
                                    loop="true"
                                    :breakpoints="{
                                    640: {
                                        slidesPerView: 1,
                                    },
                                    768: {
                                        slidesPerView: 1,
                                    },
                                    1024: {
                                        slidesPerView: 1,
                                    }
                                    }" >
                                        <swiper-slide>
                                            <img src="../assets/img/CaseStudies/CaseStudies1.webp" class="img-fluid" alt="aretegroup" srcset="">
                                        </swiper-slide>
                                        <swiper-slide>
                                            <img src="../assets/img/CaseStudies/CaseStudies2.webp" class="img-fluid" alt="aretegroup" srcset="">
                                        </swiper-slide>
                                        <swiper-slide><img src="../assets/img/CaseStudies/CaseStudies3.webp" class="img-fluid" alt="aretegroup" srcset=""></swiper-slide>

                                    </swiper>

                           
                        </div>
                        <div class="col-lg-5 col-md-12 d-flex align-items-center" data-aos="fade-up">
                                
                            <div class="container">
                                <div class="row p-0 p-lg-5">
                                    <swiper :slidesPerView="1" 
                                    :spaceBetween="40"
                                    :centeredSlides="true"
                                    :autoplay="{
                                        delay: 2500,
                                        disableOnInteraction: false,
                                    }"
                                    :pagination="false"
                                    :navigation="false"
                                    :modules="modules"
                                    loop="true"
                                    :breakpoints="{
                                    640: {
                                        slidesPerView: 1,
                                    },
                                    768: {
                                        slidesPerView: 1,
                                    },
                                    1024: {
                                        slidesPerView: 1,
                                    }
                                    }" >
                                        <swiper-slide><h2 class="main-title-sub">Fortune Park Galaxy</h2><h2 class="case1">In late 1990s, Vapi and its surrounding areas were bustling with industries..</h2></swiper-slide>
                                        <swiper-slide><h2 class="main-title-sub">Moda</h2><h2 class="case1">Women in Vapi & neighboring catchments encountered the pervasive challenge of finding comprehensive..</h2></swiper-slide>
                                        <swiper-slide><h2 class="main-title-sub">Galaxy High Street</h2><h2 class="case1">In the year [2004], Vapi and its neighboring areas, including Valsad, Daman & Silvassa ..</h2></swiper-slide>
                                        
                                    </swiper>

                                    
                                    <br>
                                </div>
                            </div>
                        </div>
                        
                        <div class="d-flex justify-content-center" >
                            <hr class="bottomline">
                        </div>                
            </div>
        </div>
    </section>

  </div>
</template>
<script>

import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import { useHead } from '@vueuse/head';

useHead({
  title: 'Arete Group',
  meta: [
    {
      name: 'description',
      content: 'Founded in 1990, The Arete Group has grown steadily, diversifying across sectors with a foundation of strong values, resilience, and purposeful expansion.',
    },
    {
      property: 'og:title',
      content: 'Arete Group',
    },
    {
      property: 'og:description',
      content: 'Founded in 1990, The Arete Group has grown steadily, diversifying across sectors with a foundation of strong values, resilience, and purposeful expansion.',
    },
    {
      property: 'og:image',
      content: 'https://www.aretegroup.in/logo.png', // Image for social sharing
    },
    {
      name: 'keywords',
      content: 'Arete Group, Arete Group of Companies, Industrial Parks India, Industrial park business, Payal Industrial Park, arete leadership, Arete Chemical Parks, Arete Warehousing Solutions, Arete Hospitality & Restaurants, Arete Retail Leasing, Arete Real Estate Development, Arete Investments, Arete Legacy, Arete Growth Story, arete group vapi',
    },
  ],
});

export default {
    components: {
      Swiper,
      SwiperSlide
    },
    setup() {
      return {
        modules: [Autoplay, Pagination, Navigation],
      };
    },
  data() {
    return {
        activeTab: 'pillsHome',  // Default active tab
        videoSupported: true, // Tracks whether video is supported
        fallbackImage: require('../assets/img/Arete.png'), // Path to the fallback image
        isHidden: true,
        loader:true,
        autoplay:false,
        mutedtx: require('../assets/img/muted.png'), // Image for muted state
        unmutedtx: require('../assets/img/unmuted.png'), // Image for unmuted state
        srcmuted:require('../assets/img/unmuted.png'),
      values: [
        { count: 34, text: "years of journey" },
        { count: 5, text: "million sq. ft. of commercial and residential land bank" },
        { count: 7000, text: "acres of Industrial Parks" },
        { count: 10, text: "Owned brands" }
      ]
    };
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
    this.checkInViewport();
    this.createIntersectionObserver();
    // Check if video is supported
    const videoElement = this.$refs.introVideo;
 
    // If the browser doesn't support video formats, show the fallback image
    if (!videoElement.canPlayType) {
      this.videoSupported = false;
    }

    setTimeout(() => {
      this.loader = false;
      this.scrollerdown();
      this.checkPageLoaded(); // Ensure loader stays until full page load
      //this.autoplay = true;
    }, 4000); // Adjust timeout duration based on your requirements
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    setActiveTab(tab) {
        setTimeout(() => {
            this.activeTab = tab;
        },500);
    },
    createIntersectionObserver() {
      const section = this.$el.querySelector(".videomutesec");
      const options = {
        root: null,
        threshold: 0.5, // Trigger when 50% of the section is in view
      };
 
      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            this.muteVideo();
          }
        });
      }, options);
 
      observer.observe(section);
    },
    muteVideo() {
        const video = this.$refs.introVideo; // Use the correct ref name here
      if (video) {
        // Toggle muted state
        video.muted = true;

        // Optionally play the video if it's unmuted
        if (!video.muted && this.srcmuted) {

          video.play();
          this.srcmuted=this.unmutedtx;
        }
        else
        {
           this.srcmuted=this.unmutedtx;
        }
        }
    }, ////////////only for mute sect close
    checkPageLoaded() {
        // Check if entire page (including images and other content) is loaded
        window.addEventListener('load', () => {
          this.loader = false;
        });
      },
    handleVideoError() {
      // Called when there is a video error
      this.videoSupported = false;
    },
    toggleMute() {
      const video = this.$refs.introVideo; // Use the correct ref name here
      if (video) {
        // Toggle muted state
        video.muted = !video.muted;

        // Optionally play the video if it's unmuted
        if (!video.muted && this.srcmuted) {

          video.play();
          this.srcmuted=this.mutedtx;
        }
        else
        {
            this.srcmuted=this.unmutedtx;
        }


        
        // Log the current muted state (optional)
        console.log(`Video is now ${video.muted ? 'muted' : 'unmuted'}.`);
      } else {
        console.error("Video element is not defined.");
      }
    },
    hideArrow() {
      setTimeout(() => {
        this.isHidden = false;
      }, 70);
    },
    showArrow() {
      this.isHidden = true;
    },
    handleScroll() {
      this.checkInViewport();
    },
    scrollerdown()
    {
        const video = this.$refs.introVideo; // Use the correct ref name here
            // Toggle muted state
        video.play();
    },
    checkInViewport() {
      const section = this.$el.querySelector('.valuesSection');
      if (this.isInViewport(section)) {
        this.counter();
        // Remove the scroll event listener after triggering the counter
        window.removeEventListener('scroll', this.handleScroll);
      }
    },
    isInViewport(element) {
        const rect = element.getBoundingClientRect();
      return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth)
      );
    },
    counter() {
        
      const counters = this.$el.querySelectorAll('.counter-count');
      counters.forEach((counter) => {
        const target = parseInt(counter.textContent, 10);
        let count = 0;
        const duration = 4000; // Change count up speed here

        const increment = target / (duration / 100);
        const animate = setInterval(() => {
          count += increment;
          if (count >= target) {
            count = target;
            clearInterval(animate);
          }
          counter.textContent = Math.ceil(count);
        }, 100);
      });
    }
  }
};

</script>

<style scoped>
.ellipse-arrow11 {
    width: 56px;
    height: 56px;
    border-radius: 50%; /* Makes it an ellipse */
    background: linear-gradient(45deg, rgba(120, 205, 208, 1), rgba(191, 212, 47, 1));
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative; /* For positioning the arrow */
}
 

.ellipse-arrow-gi {
    width: 45px; /* Adjust as needed */
    height: 38px; /* Adjust as needed */
    background-size: contain; /* Adjust background size */
    background-color: linear-gradient(45deg, rgba(120, 205, 208, 1), rgba(191, 212, 47, 1));
}

.bannerbg{
    background: #fdfefd;
}
/* Add your styles here */
/* section our values */
.values-custom-card {
    border-left: 1px solid rgb(102, 229, 102);
    border-bottom: 1px solid rgb(92, 209, 92);
    border-top: 1px solid rgb(43, 255, 230);
    border-right: 1px solid rgb(43, 255, 230);
    /* margin-bottom: 20px; */
    transition: background-color 0.3s ease;
    overflow: hidden;
    /* Ensure content stays within the card */
}
.values-custom-card-1 {
    border-left: 1px solid rgb(102, 229, 102);
    border-bottom: 1px solid rgb(92, 209, 92);
    border-top: 1px solid rgb(43, 255, 230);
    border-right: 1px solid rgb(43, 255, 230);
    /* margin-bottom: 20px; */
    transition: background-color 0.3s ease;
    overflow: hidden;
    /* Ensure content stays within the card */
}
.values-custom-card-2 {
    border-left: 1px solid rgb(102, 229, 102);
    border-bottom: 1px solid rgb(92, 209, 92);
    border-top: 1px solid rgb(43, 255, 230);
    border-right: 1px solid rgb(43, 255, 230);
    /* margin-bottom: 20px; */
    transition: background-color 0.3s ease;
    overflow: hidden;
    /* Ensure content stays within the card */
}
.values-custom-card-3 {
    border-left: 1px solid rgb(102, 229, 102);
    border-bottom: 1px solid rgb(92, 209, 92);
    border-top: 1px solid rgb(43, 255, 230);
    border-right: 1px solid rgb(43, 255, 230);
    /* margin-bottom: 20px; */
    transition: background-color 0.3s ease;
    overflow: hidden;
    /* Ensure content stays within the card */
}
 
.values-custom-card:hover,.values-custom-card-1:hover,.values-custom-card-2:hover,.values-custom-card-3:hover {
    background-color: #BFD730;
}

.values-custom-card:hover .values-card-img-top {
    content: url('/src/assets/svg/values_1hover.svg');
    transform: scale(1.3);
}
.values-custom-card-1:hover .values-card-img-top {
    content: url('/src/assets/svg/Values_2hover.svg');
    transform: scale(1.3);
}
.values-custom-card-2:hover .values-card-img-top {
    content: url('/src/assets/svg/Values_3hover.svg');
    transform: scale(1.3);
}
.values-custom-card-3:hover .values-card-img-top {
    content: url('/src/assets/svg/Values_4hover.svg');
    transform: scale(1.3);
}


/* ---- */
#loading-wrapper {
  width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 999999;
    position: fixed;
    background: #000000ab;
}

#loading-text {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  color: #999;
  width: 100px;
  height: 30px;
  margin: -7px 0 0 -45px;
  text-align: center;
  font-size: 20px;
}

#loading-content {
  display: block;
  position: relative;
  width: 100%;
  height: 100%;
  background-color: white
}

#preloader-image {
  width: 50%; /* Adjust this size as needed */
  height: auto; /* Adjust this size as needed */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}




.nav-pills .nav-link.active{
    background-color: linear-gradient(45deg, rgba(120, 205, 208, 1), rgba(191, 212, 47, 1));
}


@media (max-width: 600px) {
    .card {
        height: 150px;
    }
}


</style>

